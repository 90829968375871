<template>
    <div class="subcard" v-if="subcard">
        <div class="filler"></div>
        <div class="imageCont">
            <Image v-if="subcard.logo" :image="(subcard.logo as Image)" :width="400" imageClass="contain" :blurhash="false" />
        </div>

        <a v-if="link.address && link.external" class="overlink" :href="link.address" target="_blank" :aria-label="subcard.logo?.title"></a>
        <NuxtLinkLocale v-if="link.address && !link.external" class="overlink" :to="link.address" :aria-label="subcard.logo?.title"></NuxtLinkLocale>
    </div>
</template>

<script lang="ts" setup>
import type { Image } from '~/utils/schema';
const { getCardLink } = useCard()
const props = defineProps<{ subcard: any }>()

const link = computed(() => {
    if (props.subcard.tipologia == 'link_interno') return getCardLink(props.subcard.link_interno[0], props.subcard.link_interno[0].item.__typename)
    else return {
        address: props.subcard.link_esterno,
        external: true
    }
})
</script>