<template>
    <div>
        <div class="prefooter" v-if="footer && prefooter && footerCards.length > 0">
            <div class="cont">
                <div :class="['cards', `grid${footerCards.length}`]">
                    <WebsiteFooterCard v-for="card in footerCards" :key="card.id" :card="card" :vertical="footerCards.length === 3" />
                </div>
                <div :class="['images', { images2: footer.subcard?.length && footer.subcard?.length <= 2 }, { images3: footer.subcard?.length && footer.subcard?.length == 3 }, { images4: footer.subcard?.length && footer.subcard?.length >= 2 }]">
                    <WebsiteFooterSubcard v-for="subcard in footer.subcard" :subcard="subcard?.footer_subcard_id" />
                </div>
                <div class="sponsors" v-if="footer.sponsor && footer.sponsor.length > 0">
                    <div class="head">
                        <div class="title2" v-if="footer.translations">{{ footer.translations[0]?.sponsor }}</div>
                        <div class="controls">
                            <NButton size="medium" secondary circle @click="swipePrev" tabindex="-1" :aria-label="$t('indietro')">
                                <template #icon>
                                    <NIcon size="1.412rem" color="var(--color-text-default)" :component="KeyboardArrowLeftRound" aria-hidden="true" :aria-label="$t('indietro')"></NIcon>
                                </template>
                            </NButton>
                            <NButton size="medium" secondary circle @click="swipeNext" tabindex="-1" :aria-label="$t('avanti')">
                                <template #icon>
                                    <NIcon size="1.412rem" color="var(--color-text-default)" :component="KeyboardArrowRightRound" aria-hidden="true" :aria-label="$t('avanti')"></NIcon>
                                </template>
                            </NButton>
                        </div>
                    </div>
                    <swiper-container :slides-per-view="4" :slides-per-group="6" :grab-cursor="true" :space-between="10" :loop="true" :breakpoints="{ 601: { slidesPerView: 6 } }" ref="subcardSwiper">
                        <swiper-slide class="sponsorCont" v-for="sponsor in footer.sponsor">
                            <WebsiteFooterSubcard :subcard="sponsor?.footer_subcard_id" />
                        </swiper-slide>
                    </swiper-container>
                </div>
            </div>
        </div>
        <footer v-if="footer">
            <div class="largecont">
                <div class="zoneLinks">
                    <div class="links column1">
                        <template v-for="link in footer.colonna_1">
                            <div v-if="link?.translations">
                                <NuxtLinkLocale :to="getCardLink(link.pagina[0], link.pagina[0]?.item?.__typename).address" v-if="link?.pagina[0]">{{ link.translations[0]?.etichetta }}</NuxtLinkLocale>
                                <a v-else-if="link.link_esterno" :href="link.link_esterno" target="_blank">{{ link.translations[0]?.etichetta }}</a>
                                <span v-else>{{ link.translations[0]?.etichetta }}</span>
                            </div>
                        </template>
                    </div>
                    <div class="links column2">
                        <template v-for="link in footer.colonna_2">
                            <div v-if="link?.translations">
                                <NuxtLinkLocale :to="getCardLink(link.pagina[0], link.pagina[0]?.item?.__typename).address" v-if="link?.pagina[0]">{{ link.translations[0]?.etichetta }}</NuxtLinkLocale>
                                <a v-else-if="link.link_esterno" :href="link.link_esterno" target="_blank">{{ link.translations[0]?.etichetta }}</a>
                                <span v-else>{{ link.translations[0]?.etichetta }}</span>
                            </div>
                        </template>
                    </div>
                    <div class="links column3">
                        <template v-for="link in footer.colonna_3">
                            <div v-if="link?.translations">
                                <NuxtLinkLocale :to="getCardLink(link.pagina[0], link.pagina[0]?.item?.__typename).address" v-if="link?.pagina[0]">{{ link.translations[0]?.etichetta }}</NuxtLinkLocale>
                                <a v-else-if="link.link_esterno" :href="link.link_esterno" target="_blank">{{ link.translations[0]?.etichetta }}</a>
                                <span v-else>{{ link.translations[0]?.etichetta }}</span>
                            </div>
                        </template>
                    </div>
                    <div class="links column4">
                        <template v-for="link in footer.colonna_4">
                            <div v-if="link?.translations">
                                <NuxtLinkLocale :to="getCardLink(link.pagina[0], link.pagina[0]?.item?.__typename).address" v-if="link?.pagina[0]">{{ link.translations[0]?.etichetta }}</NuxtLinkLocale>
                                <a v-else-if="link.link_esterno" :href="link.link_esterno" target="_blank">{{ link.translations[0]?.etichetta }}</a>
                                <span v-else>{{ link.translations[0]?.etichetta }}</span>
                            </div>
                        </template>
                        <div><span class="cookieLink" @click="openCookieBanner">{{ $t('modifica_consenso_cookie') }}</span></div>
                    </div>
                    <div class="icons">
                        <template v-for="sponsor in footer.colonna_5">
                            <a v-if="sponsor" :href="sponsor?.link || '#'" target="_blank" rel="nofollow noopener" :style="{ width: `${sponsor.larghezza_in_footer}px`, height: `${sponsor.logo.height * sponsor.larghezza_in_footer / sponsor.logo.width}px` }">
                                <Image v-if="sponsor?.logo" :image="(sponsor?.logo as Image)" :width="400" imageClass="contain" :blurhash="false" />
                            </a>
                        </template>
                    </div>
                </div>
                <div class="zoneBottom">
                    <div class="copy" v-if="footer.translations" v-html="footer.translations[0]?.fine_pagina"></div>
                    <div class="socials">
                        <a v-for="social in footer.social" :href="social.link" target="_blank" rel="nofollow noopener" :aria-label="social.icon">
                            <NButton size="small" secondary circle aria-label="Social">
                                <template #icon>
                                    <NIcon color="var(--color-text-default)" v-if="social.icon == 'facebook'" aria-label="Facebook">
                                        <IconFacebook />
                                    </NIcon>
                                    <NIcon size="0.706rem" color="var(--color-text-default)" v-else-if="social.icon == 'twitter'" aria-label="Twitter">
                                        <IconTwitter />
                                    </NIcon>
                                    <NIcon size="1rem" color="var(--color-text-default)" v-else-if="social.icon == 'instagram'" aria-label="Instagram">
                                        <IconInstagram />
                                    </NIcon>
                                    <NIcon size="0.882rem" color="var(--color-text-default)" v-else-if="social.icon == 'tiktok'" aria-label="TikTok">
                                        <IconTikTok />
                                    </NIcon>
                                    <NIcon color="var(--color-text-default)" v-else-if="social.icon == 'youtube'" aria-label="YouTube">
                                        <IconYoutube />
                                    </NIcon>
                                    <NIcon size="0.941rem" color="var(--color-text-default)" v-else-if="social.icon == 'linkedin'" aria-label="LinkedIn">
                                        <IconLinkedInGhost />
                                    </NIcon>
                                </template>
                            </NButton>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script lang="ts" setup>
import type { Image } from '~/utils/schema';
import { NButton, NIcon } from 'naive-ui'
import { KeyboardArrowLeftRound, KeyboardArrowRightRound } from '@vicons/material'

interface Props {
    prefooter?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
    prefooter: true
})

const { getCardLink } = useCard()
const { locale } = useI18n()
const route = useRoute()
const directus = useDirectus()
const preferences = usePreferencesStore()

const { bannerClosed } = storeToRefs(preferences)
const subcardSwiper = ref<any>(null)

const radicePagina = computed(() => {
    if (route.path && props.prefooter === true) {
        if (route.path.indexOf('/it/visit') > -1 || route.path.indexOf('/en/visit') > -1 || route.path.indexOf('/fr/visit') > -1) return 'visita'
        else if (route.path.indexOf('/it/conv') > -1 || route.path.indexOf('/en/conv') > -1 || route.path.indexOf('/fr/conv') > -1) return 'convention'
        else if (route.path.indexOf('/it/trav') > -1 || route.path.indexOf('/en/trav') > -1 || route.path.indexOf('/fr/trav') > -1) return 'trade'
        else if (route.path.indexOf('/it/scuo') > -1 || route.path.indexOf('/en/schoo') > -1 || route.path.indexOf('/fr/ecol') > -1) return 'scuole'
        else if (route.path.indexOf('/it/press') > -1 || route.path.indexOf('/en/press') > -1 || route.path.indexOf('/fr/press') > -1) return 'visita'
        else if (route.path.indexOf('/it/partner') > -1 || route.path.indexOf('/en/partner') > -1 || route.path.indexOf('/fr/partner') > -1) return 'visita'
        return 'visita'
    } else return undefined
})

const { data: footer } = useAsyncData(`footer-${locale.value}-${radicePagina.value}`, () => directus('/pages/content/part/footer', { query: { locale: locale.value, prefooter: radicePagina.value } }), { transform: (p: any) => p?.footer })

const footerCards = computed(() => {
    if (footer.value && footer.value) {
        const cards: any[] = []
        if (footer.value.card) cards.push(...footer.value.card)
        else if (footer.value.card_convention) cards.push(...footer.value.card_convention)
        else if (footer.value.card_trade) cards.push(...footer.value.card_trade)
        else if (footer.value.card_scuole) cards.push(...footer.value.card_scuole)

        return cards
    } else return []
})

const swipeNext = () => {
    subcardSwiper.value?.swiper?.slideNext()
}
const swipePrev = () => {
    subcardSwiper.value?.swiper?.slidePrev()
}

const openCookieBanner = () => {
    bannerClosed.value = false
}
</script>

<style lang="scss" scoped>
.prefooter {
    background-color: var(--color-background-prefooter);
    padding: var(--margin-article) 0;

    font-size: var(--font-size-footer);
    line-height: var(--font-line-footer);
    font-weight: 400;

    .cont {
        padding: 0 var(--margin-page);
    }

    .cards {
        display: flex;
        flex-direction: column;
        gap: var(--margin-default);

        &.grid3 {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: var(--margin-gap);
        }

        &.grid4,
        &.grid5,
        &.grid6 {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            gap: var(--margin-gap);
        }
    }

    .images {
        margin-top: var(--margin-default);
        display: grid;
        gap: var(--margin-gap);

        &.images2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &.images3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &.images4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &:deep(.subcard) {
            position: relative;
            background-color: var(--color-background-box);
            border-radius: var(--size-card-radius);
            max-height: 250px;
            overflow: hidden;

            .filler {
                padding-top: 75%;
            }

            .imageCont {
                position: absolute;
                top: var(--margin-double);
                left: var(--margin-double);
                right: var(--margin-double);
                bottom: var(--margin-double);
            }
        }
    }

    .sponsors {
        margin-top: var(--margin-article);

        .head {
            margin-bottom: var(--margin-double);
            display: flex;
            justify-content: space-between;

            .controls {
                display: flex;
                flex-direction: row;
                gap: var(--margin-gap);
            }
        }

        &:deep(.subcard) {
            position: relative;
            background-color: var(--color-background-box);
            border-radius: var(--size-card-radius);
            overflow: hidden;

            .filler {
                padding-top: 90%;
            }

            .imageCont {
                position: absolute;
                top: var(--margin-double);
                left: var(--margin-double);
                right: var(--margin-double);
                bottom: var(--margin-double);
            }
        }
    }
}

footer {
    padding: var(--margin-article) 0 var(--margin-double) 0;
    background-color: var(--color-background-footer);
    color: #FAFAFA;

    font-size: var(--font-size-footer);
    line-height: var(--font-line-footer);
    font-weight: 400;

    .zoneLinks {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: var(--margin-default);

        padding-bottom: var(--margin-double);

        .links {

            div {
                padding: 10px 0;
                -webkit-column-break-inside: avoid;
                page-break-inside: avoid;
                break-inside: avoid;

                a,
                .cookieLink {
                    cursor: pointer;
                    text-decoration: none;
                    text-underline-offset: 4px;
                    text-decoration-thickness: 1px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .icons {
            position: relative;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            gap: var(--margin-default);

            a {
                position: relative;
                display: block;
            }

            &:deep(img) {
                position: relative;
                height: auto;
            }
        }
    }

    .zoneBottom {
        border-top: 1px solid var(--color-background-gray);
        padding-top: var(--margin-double);

        display: flex;
        justify-content: space-between;

        .socials {
            display: flex;
            flex-direction: row;
            gap: var(--margin-gap);

            .social {
                height: 20px;
                width: 20px;
                background-color: white;
                border-radius: 10px;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    .prefooter {
        .images {
            &:deep(.subcard) {
                .imageCont {
                    top: var(--margin-default);
                    left: var(--margin-default);
                    right: var(--margin-default);
                    bottom: var(--margin-default);
                }
            }
        }

        .sponsors {
            &:deep(.subcard) {
                .imageCont {
                    top: var(--margin-default);
                    left: var(--margin-default);
                    right: var(--margin-default);
                    bottom: var(--margin-default);
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .prefooter {
        padding: var(--margin-double) 0;

        .images {
            grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
            gap: var(--margin-default);
        }

        .sponsors {
            margin-top: var(--margin-double);

            &:deep(.subcard) {
                .imageCont {
                    top: var(--margin-gap);
                    left: var(--margin-gap);
                    right: var(--margin-gap);
                    bottom: var(--margin-gap);
                }
            }
        }
    }

    footer {
        padding: var(--margin-double) 0 var(--margin-page) 0;

        .zoneLinks {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 0 var(--margin-default);

            .links {

                &.column1 {
                    grid-column: 1;
                    grid-row: 1 / span 2;
                }

                &.column2 {
                    grid-column: 1;
                    grid-row: 3;
                }
            }

            .icons {
                margin-top: var(--margin-double);

                grid-column: 1 / span 2;
                grid-row: 4;

                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                align-items: center;
                gap: var(--margin-default);

                a {
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
        }

        .zoneBottom {
            flex-direction: column;
            gap: var(--margin-double);

            .socials {
                order: 1;
                justify-content: center;
            }

            .copy {
                order: 2;
            }
        }
    }
}
</style>