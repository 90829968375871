const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
}

export default defineNuxtPlugin(() => {
    return {
        provide: {
            dataSenzaOrario: (input: string) => {
                if (input) {
                    const { localeProperties } = useI18n()
                    const data = new Date(input)
                    return data.toLocaleDateString(localeProperties.value.language, { day: 'numeric', month: 'numeric', year: 'numeric' })
                } else return ''
            },
            dataDataSenzaOrario: (data: Date) => {
                if (data) {
                    const { localeProperties } = useI18n()
                    return data.toLocaleDateString(localeProperties.value.language, { day: 'numeric', month: 'numeric', year: 'numeric' })
                } else return ''
            },
            dataCompletaSenzaOrario: (input: string) => {
                if (input) {
                    const { localeProperties } = useI18n()
                    const data = new Date(input)
                    return data.toLocaleDateString(localeProperties.value.language, { day: 'numeric', month: 'long', year: 'numeric' })
                } else return ''
            },
            dataSenzaAnno: (input: string) => {
                if (input) {
                    const { localeProperties } = useI18n()
                    const data = new Date(input.replace('/', '-'))
                    return data.toLocaleDateString(localeProperties.value.language, { day: 'numeric', month: 'numeric' })
                } else return ''
            },
            orario: (input: string) => {
                if (input) {
                    const { localeProperties } = useI18n()
                    const data = new Date(input)
                    return data.toLocaleTimeString(localeProperties.value.language, { hour: 'numeric', minute: 'numeric' })
                } else return ''
            },
            oraSenzaSecondi: (input: string) => {
                if (input) {
                    const numeri = input.split(':')
                    return `${numeri[0]}:${numeri[1]}`
                } else return ''
            },
            dataConOrario: (input: string) => {
                if (input) {
                    const { localeProperties } = useI18n()
                    const data = new Date(input)
                    const dataSenzaOrario = data.toLocaleDateString(localeProperties.value.language, { day: 'numeric', month: 'long', year: 'numeric' })
                    const orario = data.toLocaleTimeString(localeProperties.value.language, { hour: 'numeric', minute: 'numeric' })
                    return `${dataSenzaOrario} | ore ${orario}`
                } else return ''
            },
            dataStandard: (input: Date) => {
                return [
                    input.getFullYear(),
                    padTo2Digits(input.getMonth() + 1),
                    padTo2Digits(input.getDate()),
                ].join('-');
            },
            formatEuro: (prezzo: number, divisoCento: boolean = true) => {
                const { localeProperties } = useI18n()
                const euro = new Intl.NumberFormat(localeProperties.value.language, { style: 'currency', currency: 'EUR' });
                if (prezzo) {
                    if (divisoCento) return euro.format(prezzo / 100)
                    else return euro.format(prezzo)
                } else {
                    return euro.format(0)
                }
            },
            padTo2Digits: padTo2Digits
        }
    }
})