<template>
    <NConfigProvider :locale="themeLocale" :theme-overrides="themeOverrides" inline-theme-disabled>
        <div>
            <DelayHydration>
                <LazyWebsiteHeaderWrapper />
            </DelayHydration>
            <main id="mainContent">
                <section>
                    <WebsiteHeroForm :titolo="error?.statusCode.toString()">
                        <template #subtitle>
                            <div class="innerCont">
                                <div class="paragraph" v-if="error?.statusCode == 404">
                                    <p v-if="locale == 'it'">Oops! Questa pagina non è al momento disponibile, utilizza il Menu e la funzione "cerca" per trovare le informazioni più utili per te oppure <NuxtLinkLocale to="/visita/pianifica-la-tua-visita/uffici-del-turismo">contattaci</NuxtLinkLocale>.</p>
                                    <p v-else-if="locale == 'fr'">Vous êtes perdu? Cette page est introuvable, mais ne vous inquiétez pas, nous vous remettrons sur le bon chemin: <NuxtLinkLocale to="/">Home</NuxtLinkLocale> ou <NuxtLinkLocale to="/decouvrir/preparer-ton-sejour/offices-de-tourisme">Contactez-nous</NuxtLinkLocale>!</p>
                                    <p v-else-if="locale == 'en'">Oops! We couldn't find that page. It looks like nothing is here. Let’s get you back on track: <NuxtLinkLocale to="/">Home</NuxtLinkLocale> or <NuxtLinkLocale to="/visit/plan-your-trip/tourist-offices">Contact Us</NuxtLinkLocale>.</p>
                                </div>
                                <div class="paragraph" v-else>
                                    <p>{{ error?.message }}</p>
                                </div>
                            </div>
                        </template>
                    </WebsiteHeroForm>
                </section>
                <WebsiteFooter />
            </main>
        </div>
    </NConfigProvider>
</template>

<script setup lang="ts">
import type { GlobalThemeOverrides } from 'naive-ui'
import { NConfigProvider, itIT, enGB, frFR } from 'naive-ui'
import type { NuxtError } from "nuxt/app";
const { locale } = useI18n()

interface Props {
    error?: NuxtError
}
const props = defineProps<Props>()

const colorAzure = '#5AC4ED'
const colorAzureLight = '#9DE3F9'
const colorAzureDark = '#327084'
const colorRed = '#E2001A';
const colorGray = '#DBDBDE';
const colorGrayLight = 'rgba(46,51,56,0.05)';
const colorBorderInput = 'rgb(224, 224, 230)';
const colorGreen = '#009BB0'
const colorGreenHover = '#009BB0'
const colorGreenPressed = '#009BB0'
const colorTextDefault = '#1A1B3A'
const colorTextDefaultHover = '#000000'
const colorTextContrast = '#FFFFFF'
const colorBackgroundPage = '#F0F1F3'

const themeLocale = computed(() => {
    if (locale.value == 'en') return enGB
    else if (locale.value == 'fr') return frFR
    else return itIT
})
const themeOverrides = <GlobalThemeOverrides>{
    common: {
        bodyColor: colorBackgroundPage,
        successColor: colorGreen,
        errorColor: colorRed,
        fontFamily: "'Switzer', 'Switzer override', 'BlinkMacSystemFont', 'Segoe UI', 'Helvetica Neue', 'Arial', 'Noto Sans'",

        primaryColor: colorAzure
    },
    Button: {
        heightLarge: '54px',
        heightMedium: '40px',
        paddingMedium: '0 0',
        paddingRoundLarge: '0 25px',
        paddingRoundSmall: '0 18px',

        color: colorGrayLight,
        colorHover: colorGray,
        colorFocus: colorGray,
        colorPressed: colorGrayLight,
        colorPrimary: colorAzure,
        colorHoverPrimary: colorAzureLight,
        colorFocusPrimary: colorAzureLight,
        colorPressedPrimary: colorAzure,
        colorSecondary: 'white',
        colorSecondaryHover: 'white',
        colorSecondaryPressed: 'white',
        colorTertiary: 'transparent',
        colorTertiaryHover: 'transparent',
        colorTertiaryPressed: 'transparent',

        textColor: colorTextDefault,
        textColorHover: colorTextDefaultHover,
        textColorFocus: colorTextDefaultHover,
        textColorPressed: colorTextDefault,
        textColorPrimary: colorTextDefault,
        textColorHoverPrimary: colorTextDefault,
        textColorFocusPrimary: colorTextDefault,
        textColorPressedPrimary: colorTextDefault,
        textColorTertiary: colorAzure,

        colorDisabled: 'rgba(240,240,240,0.5)',
        colorDisabledPrimary: 'rgba(240,240,240,0.5)',
        colorError: colorAzure,
        colorHoverError: colorAzureLight,
        colorFocusError: colorAzure,
        colorPressedError: colorAzureLight,

        textColorSuccess: colorTextDefault,
        textColorHoverSuccess: colorTextDefaultHover,
        textColorFocusSuccess: colorTextDefault,
        textColorGhostPressedSuccess: colorTextDefaultHover,
        colorSuccess: colorGreen,
        colorHoverSuccess: colorGreenHover,
        colorFocusSuccess: colorGreen,
        colorPressedSuccess: colorGreenPressed,
        textColorError: colorTextDefault,
        textColorHoverError: colorTextDefaultHover,
        textColorFocusError: colorTextDefault,
        textColorGhostPressedError: colorTextDefaultHover,

        textColorDisabled: colorGray,
        textColorDisabledPrimary: colorGray,

        borderRadiusLarge: '12px',

        fontWeight: 700,
        fontSizeLarge: '0.941rem', // 16px
        fontSizeMedium: '0.941rem', // 16px
        fontSizeSmall: '0.882rem', // 15px

        textColorGhost: colorTextDefault,
        textColorGhostHover: colorTextDefault,
        textColorGhostPressed: colorTextDefault,
        textColorGhostFocus: colorTextDefault,

        //border: '2px solid var(--color-text-default)',
        border: 'none',
        borderPrimary: 'none',
        borderWarning: 'none',
        borderSuccess: 'none',
        borderError: 'none',
        borderDisabled: 'none',
        borderDisabledPrimary: 'none',
        borderFocus: 'none',
        borderFocusPrimary: 'none',
        borderFocusWarning: 'none',
        borderFocusSuccess: 'none',
        borderFocusError: 'none',
        borderPressed: 'none',
        borderPressedPrimary: 'none',
        borderPressedWarning: 'none',
        borderPressedSuccess: 'none',
        borderPressedError: 'none',
        borderHover: 'none',
        borderHoverPrimary: 'none',
        borderHoverWarning: 'none',
        borderHoverSuccess: 'none',
        borderHoverError: 'none'

    },
    Input: {
        heightLarge: '54px',
        paddingLarge: '0 20px',

        textColor: colorTextDefault,

        borderRadius: '12px',
        border: 'none',
        borderHover: 'none',
        borderFocus: 'none',

        fontSizeLarge: '0.941rem', // 16px
    },
}
</script>

<style lang="scss" scoped>
section {
    min-height: calc(100vh - 200px);
}
.innerCont {
    p {
        text-wrap: balance;
    }
}
</style>