<template>
    <picture class="responsivePicture" v-if="image && image.id" ref="picture" :style="{ backgroundImage: holdBackground }">
        <source :srcset="inview ? getDirectusThumbnail(image.id, { width: mobileWidth || 824, height: mobileHeight || height, format: 'avif', quality: quality - 10 }) : undefined" :data-srcset="getDirectusThumbnail(image.id, { width: mobileWidth || 824, height: mobileHeight || height, format: 'avif', quality: quality - 10 })" type="image/avif" media="(max-width: 767px)">
        <source :srcset="inview ? getDirectusThumbnail(image.id, { width: mobileWidth || 824, height: mobileHeight || height, format: 'webp', quality: quality - 10 }) : undefined" :data-srcset="getDirectusThumbnail(image.id, { width: mobileWidth || 824, height: mobileHeight || height, format: 'webp', quality: quality - 10 })" type="image/webp" media="(max-width: 767px)">
        <source :srcset="inview ? getDirectusThumbnail(image.id, { width: Math.floor(width * 3 / 2), height: height ? Math.floor(height * 3 / 2) : undefined, format: 'avif', quality: quality - 5 }) : undefined" :data-srcset="getDirectusThumbnail(image.id, { width: Math.floor(width * 3 / 2), height: height ? Math.floor(height * 3 / 2) : undefined, format: 'avif', quality: quality - 5 })" type="image/avif" media="(min-width: 768px) and (max-width: 1280px)">
        <source :srcset="inview ? getDirectusThumbnail(image.id, { width: Math.floor(width * 3 / 2), height: height ? Math.floor(height * 3 / 2) : undefined, format: 'webp', quality: quality - 5 }) : undefined" :data-srcset="getDirectusThumbnail(image.id, { width: Math.floor(width * 3 / 2), height: height ? Math.floor(height * 3 / 2) : undefined, format: 'webp', quality: quality - 5 })" type="image/webp" media="(min-width: 768px) and (max-width: 1280px)">
        <source :srcset="inview ? `${getDirectusThumbnail(image.id, { width: width, height: height, format: 'avif', quality: quality })}, ${getDirectusThumbnail(image.id, { width: width * 2, height: height ? height * 2 : undefined, format: 'avif', quality: quality })} 2x` : undefined" :data-srcset="`${getDirectusThumbnail(image.id, { width: width, height: height, format: 'avif', quality: quality })}, ${getDirectusThumbnail(image.id, { width: width * 2, height: height ? height * 2 : undefined, format: 'avif', quality: quality })} 2x`" type="image/avif">
        <source :srcset="inview ? `${getDirectusThumbnail(image.id, { width: width, height: height, format: 'webp', quality: quality })}, ${getDirectusThumbnail(image.id, { width: width * 2, height: height ? height * 2 : undefined, format: 'webp', quality: quality })} 2x` : undefined" :data-srcset="`${getDirectusThumbnail(image.id, { width: width, height: height, format: 'webp', quality: quality })}, ${getDirectusThumbnail(image.id, { width: width * 2, height: height ? height * 2 : undefined, format: 'webp', quality: quality })} 2x`" type="image/webp">
        <img 
            :fetchpriority="lazyload === false ? 'high' : undefined"

            :class="[{ lazy: !loaded }, imageClass]" 
            :src="inview ? getDirectusThumbnail(image.id, { width: width * 2, height: height ? height * 2 : undefined, format: 'jpg', quality: quality }) : undefined" 
            :data-src="getDirectusThumbnail(image.id, { width: width * 2, height: height ? height * 2 : undefined, format: 'jpg', quality: quality })" 
            
            :height="height ? height * 2 : (image.height as number) * width / (image.width as number)" 
            :width="width * 2" 
            :alt="image.title ? (image.title as string).replace('.jpg', '').replace('.png', '') : ''" 
            @load="imageLoaded">
    </picture>
</template>

<script lang="ts" setup>
import { blurhashToCssGradientString } from "@unpic/placeholder";

interface Props {
    image: {
        id: string;
        height?: number;
        width?: number;
        title?: string;
        blurhash?: string;
    };
    lazyload?: boolean;
    blurhash?: boolean;
    width: number;
    mobileWidth?: number | null;
    height?: number;
    mobileHeight?: number | null;
    quality?: number;
    imageClass?: string;
}

const { getDirectusThumbnail } = useImages();
const props = withDefaults(defineProps<Props>(), {
    lazyload: true,
    height: undefined,
    quality: 70,
    blurhash: true
})

const picture = ref()
const inview = ref(props.lazyload === false ? true : false)
const loaded = ref(!props.lazyload)

useIntersectionObserver(picture,
    ([{ isIntersecting }]) => {
        if (!inview.value && isIntersecting) inview.value = true
    }
)
const holdBackground = computed(() => {
    if (props.image && props.blurhash && props.image.blurhash) return blurhashToCssGradientString(props.image.blurhash)
    else return 'transparent'
})
const imageLoaded = () => {
    loaded.value = true
}
onMounted(() => {
    if (props.lazyload === false) loaded.value = true
})
</script>

<style lang="scss">
.responsivePicture {
    display: block;
    height: 100%;
    width: 100%;

    img {
        transition: opacity 300ms 0ms ease-in-out;

        &.lazy {
            opacity: 0;
        }
    }
}
</style>