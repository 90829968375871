export const usePreferencesStore = defineStore('preferences', {
    state: () => {
        return {
            bannerClosed: false,
            gdpr: undefined,
            misurazione: false,
            marketing: false
        }
    },
    getters: {
        tagManagerConsent: (state: any) => {
            if (state.gdpr === true || (state.misurazione === true && state.marketing === true)) return true
            else return false
        }
    },
    persist: {
        key: 'ttp:preferences',
        storage: persistedState.cookiesWithOptions({
            expires: new Date(),
            maxAge: 34560000,
            sameSite: 'strict',
        })
    }
})