<template>
    <NuxtRouteAnnouncer />
    <NConfigProvider :locale="themeLocale" :theme-overrides="themeOverrides" inline-theme-disabled>
        <NuxtLayout>
            <NDialogProvider>
                <NuxtPage />
            </NDialogProvider>
        </NuxtLayout>
    </NConfigProvider>
</template>

<script lang="ts" setup>
import type { GlobalThemeOverrides } from 'naive-ui'
import { NConfigProvider, NDialogProvider } from 'naive-ui'
import { itIT, enGB, frFR } from 'naive-ui'

import '~/assets/scss/main.scss'
import '~/assets/scss/general/body.scss'
import '~/assets/scss/general/images.scss'
import '~/assets/scss/general/overrides.scss'
import '~/assets/scss/general/forms.scss'
import '~/assets/scss/modules/hero.scss';

const { locale } = useI18n()
const preferences = usePreferencesStore()
const { tagManagerConsent } = storeToRefs(preferences)

const colorAzure = '#5AC4ED'
const colorAzureLight = '#9DE3F9'
const colorAzureDark = '#327084'
const colorRed = '#E2001A';
const colorGray = '#DBDBDE';
const colorGrayLight = 'rgba(46,51,56,0.05)';
const colorGrayDark = '#7D7D7D';
const colorBorderInput = 'rgb(224, 224, 230)';
const colorGreen = '#009BB0'
const colorGreenHover = '#009BB0'
const colorGreenPressed = '#009BB0'
const colorTextDefault = '#1A1B3A'
const colorTextDefaultHover = '#000000'
const colorTextContrast = '#FFFFFF'
const colorBackgroundPage = '#F0F1F3'

const themeLocale = computed(() => {
    if (locale.value == 'en') return enGB
    else if (locale.value == 'fr') return frFR
    else return itIT
})
const themeOverrides = <GlobalThemeOverrides>{
    common: {
        bodyColor: colorBackgroundPage,
        successColor: colorGreen,
        errorColor: colorRed,
        fontFamily: "'Switzer', 'Switzer override', 'BlinkMacSystemFont', 'Segoe UI', 'Helvetica Neue', 'Arial', 'Noto Sans'",

        primaryColor: colorAzure
    },
    Button: {
        heightLarge: '54px',
        heightMedium: '40px',
        paddingMedium: '0 0',
        paddingRoundLarge: '0 25px',
        paddingRoundSmall: '0 18px',

        color: colorGrayLight,
        colorHover: colorGray,
        colorFocus: colorGray,
        colorPressed: colorGrayLight,
        colorPrimary: colorAzure,
        colorHoverPrimary: colorAzureLight,
        colorFocusPrimary: colorAzureLight,
        colorPressedPrimary: colorAzure,
        colorSecondary: 'white',
        colorSecondaryHover: 'white',
        colorSecondaryPressed: 'white',
        colorTertiary: 'transparent',
        colorTertiaryHover: 'transparent',
        colorTertiaryPressed: 'transparent',

        textColor: colorTextDefault,
        textColorHover: colorTextDefaultHover,
        textColorFocus: colorTextDefaultHover,
        textColorPressed: colorTextDefault,
        textColorPrimary: colorTextDefault,
        textColorHoverPrimary: colorTextDefault,
        textColorFocusPrimary: colorTextDefault,
        textColorPressedPrimary: colorTextDefault,
        textColorTertiary: colorAzure,

        colorDisabled: colorGray,
        colorDisabledPrimary: colorGray,
        colorError: colorAzure,
        colorHoverError: colorAzureLight,
        colorFocusError: colorAzure,
        colorPressedError: colorAzureLight,

        textColorSuccess: colorTextDefault,
        textColorHoverSuccess: colorTextDefaultHover,
        textColorFocusSuccess: colorTextDefault,
        textColorGhostPressedSuccess: colorTextDefaultHover,
        colorSuccess: colorGreen,
        colorHoverSuccess: colorGreenHover,
        colorFocusSuccess: colorGreen,
        colorPressedSuccess: colorGreenPressed,
        textColorError: colorTextDefault,
        textColorHoverError: colorTextDefaultHover,
        textColorFocusError: colorTextDefault,
        textColorGhostPressedError: colorTextDefaultHover,

        textColorDisabled: colorGrayDark,
        textColorDisabledPrimary: colorGrayDark,

        borderRadiusLarge: '12px',

        fontWeight: 700,
        fontSizeLarge: '0.941rem', // 16px
        fontSizeMedium: '0.941rem', // 16px
        fontSizeSmall: '0.882rem', // 15px

        textColorGhost: colorTextDefault,
        textColorGhostHover: colorTextDefault,
        textColorGhostPressed: colorTextDefault,
        textColorGhostFocus: colorTextDefault,

        //border: '2px solid var(--color-text-default)',
        border: 'none',
        borderPrimary: 'none',
        borderWarning: 'none',
        borderSuccess: 'none',
        borderError: 'none',
        borderDisabled: 'none',
        borderDisabledPrimary: 'none',
        borderFocus: 'none',
        borderFocusPrimary: 'none',
        borderFocusWarning: 'none',
        borderFocusSuccess: 'none',
        borderFocusError: 'none',
        borderPressed: 'none',
        borderPressedPrimary: 'none',
        borderPressedWarning: 'none',
        borderPressedSuccess: 'none',
        borderPressedError: 'none',
        borderHover: 'none',
        borderHoverPrimary: 'none',
        borderHoverWarning: 'none',
        borderHoverSuccess: 'none',
        borderHoverError: 'none'

    },
    Input: {
        heightLarge: '54px',
        paddingLarge: '0 20px',

        textColor: colorTextDefault,

        borderRadius: '12px',
        border: 'none',
        borderHover: 'none',
        borderFocus: 'none',

        fontSizeLarge: '0.941rem', // 16px
    },
    Select: {
        peers: {
            InternalSelection: {
                heightLarge: '54px',
                paddingSingle: '0 20px',

                textColor: colorTextDefault,

                fontSizeLarge: '0.941rem', // 16px

                borderRadius: '12px',

                border: `1px solid ${colorBorderInput}`,
                borderHover: `1px solid ${colorBorderInput}`,
                borderFocus: `1px solid ${colorBorderInput}`,
            },
            InternalSelectMenu: {

                optionTextColor: colorTextDefault,
                optionTextColorActive: colorAzureDark,

                optionFontSizeLarge: '0.941rem', // 16px

                borderRadius: '12px',
            }
        }
    },
    Radio: {
        buttonColor: 'transparent',
        buttonColorActive: colorAzure,

        buttonTextColor: colorTextDefault,
        buttonTextColorHover: colorTextDefault,
        buttonTextColorActive: 'white',

        dotColorActive: colorAzureDark,
        boxShadowActive: `inset 0 0 0 1px ${colorAzureDark}`,
        boxShadowHover: `inset 0 0 0 1px ${colorAzureDark}`,
        boxShadowFocus: `inset 0 0 0 1px ${colorAzureDark}`,

        fontSizeLarge: '0.941rem', // 16px
    },
    Tag: {
        fontWeightStrong: 700
    },
    Checkbox: {
        colorChecked: colorAzureDark,

        boxShadowFocus: 'none',

        textColor: colorTextDefault,
        textColorHover: colorTextDefault,
        textColorActive: colorTextDefault,
    },
    Dialog: {
        textColor: colorTextDefault,
        titleTextColor: colorTextDefault,
        closeColorHover: 'white',
        closecolorAzurePressed: 'white',
        //iconColorWarning: colorPeach,

        iconSize: '36px',
        padding: 'var(--margin-double)',
        //contentMargin: '8px 0px 40px 0px'

        borderRadius: '12px'
    },
    Tabs: {
        tabTextColorActiveLine: colorAzureDark,
        tabTextColorHoverLine: colorAzureDark,

        tabFontWeight: 700,
        tabFontWeightActive: 700
    },
    Collapse: {
        titleFontWeight: 700
    },
    Dropdown: {
        optionTextColor: colorTextDefault,
        fontSizeMedium: '0.941rem', // 16px
    },
    Switch: {
        buttonBoxShadow: 'none',
        railWidthLarge: '42px',
        buttonWidthLarge: '20px',
        buttonHeightLarge: '20px',
    }
}

useSeoMeta({
    formatDetection: 'telephone=no',
    themeColor: '#F0F1F3'
})
useHead({
    htmlAttrs: {
        lang: locale.value
    },
    link: [
        { rel: 'preconnect dns-prefetch', href: 'https://cms2.turismotorino.org' },

        { rel: 'icon', type: 'image/png', href: '/favicon-96x96.png', sizes: '96x96' },
        { rel: 'icon', type: 'image/svg+xml', href: '/favicon.svg' },
        { rel: 'shortcut icon', href: '/favicon.ico' },
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
    ],
    meta: [
        { name: 'facebook-domain-verification', content: 's4kolqunlzyx0dr5psi7ymy0x4ezf0' }
    ]
})


// TAG MANAGER
useScriptGoogleTagManager({
    id: 'GTM-K7LSZMM',
    scriptOptions: {
        trigger: useScriptTriggerConsent({
            consent: tagManagerConsent
        }),
        warmupStrategy: false
    }
})
// ACCESSIWAY
const { onLoaded } = useScript('https://eu.acsbapp.com/apps/app/dist/js/app.js', {
    use() { return { acsbJS: (window as any).acsbJS } },
    trigger: new Promise(resolve => setTimeout(resolve, 5500)),
    warmupStrategy: false
})

onLoaded(({ acsbJS }) => {
    acsbJS.init({
        statementLink: '',
        footerHtml: '<a href="https://www.accessiway.com/it/home/" target="_blank">AccessiWay. The Web Accessibility Solution</a>',
        hideMobile: false,
        hideTrigger: false,
        language: locale.value || 'it',
        position: 'left',
        leadColor: '#316E86',
        triggerColor: '#1B2830',
        triggerRadius: '50%',
        triggerPositionX: 'left',
        triggerPositionY: 'bottom',
        triggerIcon: 'people',
        triggerSize: 'medium',
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
            triggerSize: 'medium',
            triggerPositionX: 'left',
            triggerPositionY: 'bottom',
            triggerOffsetX: 20,
            triggerOffsetY: 20,
            triggerRadius: '50%'
        }
    })
})
</script>