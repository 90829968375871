import type { DataSpettacolo, Orario } from '~/utils/schema'

const dizionario = {
    dal: {
        it: 'dal',
        en: 'from',
        fr: 'du'
    },
    al: {
        it: 'al',
        en: 'to',
        fr: 'au'
    },
    finoal: {
        it: 'fino al',
        en: 'until',
        fr: 'jusqu\'au'
    },
    ognigiorno: {
        it: 'Ogni giorno',
        en: 'Every day',
        fr: 'Tous les jours'
    },
    ogni: {
        it: 'Ogni',
        en: 'Every',
        fr: 'Tous les'
    },
    delmese: {
        it: 'del mese',
        en: 'of the month',
        fr: 'du mois'
    },
    e: {
        it: ' e ',
        en: ' and ',
        fr: ' et '
    },
    date: {
        it: 'date',
        en: 'dates',
        fr: 'dates'
    },
    datemultiple: {
        it: 'Date multiple',
        en: 'Multiple dates',
        fr: 'Plusieurs dates'
    },
    mdal: {
        it: 'Dal',
        en: 'From',
        fr: 'Du'
    },
    dalle: {
        it: 'dalle',
        en: 'from',
        fr: 'de'
    },
    alle: {
        it: 'alle',
        en: 'to',
        fr: 'à'
    },
    ealtredate: {
        it: 'e altre date',
        en: 'and others dates',
        fr: 'et autres dates'
    },
    ealtre: {
        it: 'e altre',
        en: 'and others',
        fr: 'et autres'
    },
    aperturasettimanale: {
        it: 'Apertura settimanale',
        en: 'Weekly opening',
        fr: 'Ouverture hebdomadaire'
    }
}
const numerali = {
    it: {
        primo: {
            singolo: 'Ogni primo',
            domenica: 'Ogni prima',
            plurale: 'Ogni primi'
        },
        secondo: {
            singolo: 'Ogni secondo',
            domenica: 'Ogni seconda',
            plurale: 'Ogni secondi'
        },
        terzo: {
            singolo: 'Ogni terzo',
            domenica: 'Ogni terza',
            plurale: 'Ogni terzi'
        },
        quarto: {
            singolo: 'Ogni quarto',
            domenica: 'Ogni quarta',
            plurale: 'Ogni quarti'
        },
        ultimo: {
            singolo: 'Ogni ultimo',
            domenica: 'Ogni ultima',
            plurale: 'Ogni ultimi'
        }
    },
    en: {
        primo: {
            singolo: 'Every first',
            domenica: 'Every first',
            plurale: 'Every first'
        },
        secondo: {
            singolo: 'Every second',
            domenica: 'Every second',
            plurale: 'Every second'
        },
        terzo: {
            singolo: 'Every third',
            domenica: 'Every third',
            plurale: 'Every third'
        },
        quarto: {
            singolo: 'Every fourth',
            domenica: 'Every fourth',
            plurale: 'Every fourth'
        },
        ultimo: {
            singolo: 'Every last',
            domenica: 'Every last',
            plurale: 'Every last'
        },
    },
    fr: {
        primo: {
            singolo: 'Tous les premiers',
            domenica: 'Tous les premiers',
            plurale: 'Tous les premiers',
        },
        secondo: {
            singolo: 'Tous les deuxièmes',
            domenica: 'Tous les deuxièmes',
            plurale: 'Tous les deuxièmes',
        },
        terzo: {
            singolo: 'Tous les troisièmes',
            domenica: 'Tous les troisièmes',
            plurale: 'Tous les troisièmes',
        },
        quarto: {
            singolo: 'Tous les quatrièmes',
            domenica: 'Tous les quatrièmes',
            plurale: 'Tous les quatrièmes',
        },
        ultimo: {
            singolo: 'Tous les derniers',
            domenica: 'Tous les derniers',
            plurale: 'Tous les derniers',
        },
    }
}
const nomiGiorni = <['lunedi', 'martedi', 'mercoledi', 'giovedi', 'venerdi', 'sabato', 'domenica']>['lunedi', 'martedi', 'mercoledi', 'giovedi', 'venerdi', 'sabato', 'domenica']
const giorni = {
    abbreviazioni: {
        it: ['LUN', 'MAR', 'MER', 'GIO', 'VEN', 'SAB', 'DOM'],
        en: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
        fr: ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM']
    },
    completi: {
        it: ['lunedi', 'martedi', 'mercoledi', 'giovedi', 'venerdi', 'sabato', 'domenica'],
        en: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        fr: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']
    },
    plurali: {
        it: ['lunedi', 'martedi', 'mercoledi', 'giovedi', 'venerdi', 'sabato', 'domenica'],
        en: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        fr: ['lundis', 'mardis', 'mercredis', 'jeudis', 'vendredis', 'samedis', 'dimanches']
    }
}
const mesi = {
    it: ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'],
    en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    fr: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
}

const TZ = { timeZone: 'Etc/UTC' }

export const useOrari = () => {

    const { localeProperties } = useI18n()

    const giorniAbbreviati = giorni.abbreviazioni[localeProperties.value.code as 'it' | 'en' | 'fr']
    const giorniLunghi = giorni.completi[localeProperties.value.code as 'it' | 'en' | 'fr']
    const giorniLunghiPlurali = giorni.plurali[localeProperties.value.code as 'it' | 'en' | 'fr']
    const numeraliMese = numerali[localeProperties.value.code as 'it' | 'en' | 'fr']
    const nomiMesi = mesi[localeProperties.value.code as 'it' | 'en' | 'fr']

    const getUTCMidnightDate = (d: string | Date) => {
        if (d instanceof Date) return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
        else {
            const date = new Date(d)
            return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        }
    }

    const formatoDataLungo = (date: Date) => {
        if (!isNaN(date.getTime())) return date.toLocaleDateString(localeProperties.value.language, { day: 'numeric', month: 'long', year: 'numeric' })
        else return null
    }
    const formatoDataCompatto = (date: Date) => {
        if (!isNaN(date.getTime())) return date.toLocaleDateString(localeProperties.value.language, { day: '2-digit', month: '2-digit', year: 'numeric' })
        else return null
    }
    const formatoOra = (date: Date) => {
        if (!isNaN(date.getTime())) return date.toLocaleDateString(localeProperties.value.language, { hour: '2-digit', minute: '2-digit' })
        else return null
    }
    const formatoOraSenzaData = (ora: string) => {
        const fixedOra = ora.length == 5 ? `${ora}:00` : ora
        if (localeProperties.value.language == 'it-IT' || localeProperties.value.language == 'fr-FR') return fixedOra.slice(0, -3)
        else return new Date('1970-01-01T' + fixedOra + 'Z').toLocaleTimeString(localeProperties.value.language, { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });
    }
    const aggiungiDalAl = (desc: string, data: any) => {
        if (data.inizio_ricorrenza && data.fine_ricorrenza) desc = `${desc}, dal ${formatoDataCompatto(data.inizio_ricorrenza)} al ${formatoDataCompatto(data.fine_ricorrenza)}`
        else if (data.inizio_ricorrenza && !data.fine_ricorrenza) desc = `${desc}, dal ${formatoDataCompatto(data.inizio_ricorrenza)}`
        else if (!data.inizio_ricorrenza && data.fine_ricorrenza) desc = `${desc}, fino al ${formatoDataCompatto(data.fine_ricorrenza)}`
        return desc
    }


    const dataGiornoConOrario = (input: string) => {
        if (input) {
            const data = new Date(input)
            return data.toLocaleDateString(localeProperties.value.language, { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' })
        } else return ''
    }
    const dataGiornoConOrarioMobile = (input: string) => {
        if (input) {
            const data = new Date(input)
            return data.toLocaleDateString(localeProperties.value.language, { weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })
        } else return ''
    }
    const dataConOrario = (input: string) => {
        if (input) {
            const data = new Date(input)
            return data.toLocaleDateString(localeProperties.value.language, { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' })
        } else return ''
    }
    const dataSenzaOrario = (input: string) => {
        if (input) {
            const data = new Date(input)
            return data.toLocaleDateString(localeProperties.value.language, { day: 'numeric', month: 'numeric', year: 'numeric' })
        } else return ''
    }
    const dataCompletaSenzaOrario = (input: string) => {
        if (input) {
            const data = new Date(input)
            return data.toLocaleDateString(localeProperties.value.language, { day: 'numeric', month: 'long', year: 'numeric' })
        } else return ''
    }
    const dataSenzaAnno = (input: string) => {
        if (input) {
            const data = new Date(input.replace('/', '-'))
            return data.toLocaleDateString(localeProperties.value.language, { day: 'numeric', month: 'numeric' })
        } else return ''
    }

    const verbalizzaSottotitolo = (date: Orario[], usa_orari_sede: boolean = false, data_inizio_orari_sede: string | null = null, data_fine_orari_sede: string | null = null) => {
        if (date.length === 0 && usa_orari_sede === false) return null

        const code = localeProperties.value.code as 'it' | 'en' | 'fr'

        if (usa_orari_sede === true) {
            const cutInizio = data_inizio_orari_sede ? getUTCMidnightDate(data_inizio_orari_sede) : null
            const cutFine = data_fine_orari_sede ? getUTCMidnightDate(data_fine_orari_sede) : null

            if (cutInizio && cutFine && cutInizio.getTime() == cutFine.getTime()) return formatoDataLungo(cutInizio)
            else if (cutInizio && cutFine) return `${dizionario.mdal[code]} ${formatoDataCompatto(cutInizio)} ${dizionario.al[code]} ${formatoDataCompatto(cutFine)}`
            else if (cutInizio && !cutFine) return `${dizionario.mdal[code]} ${formatoDataCompatto(cutInizio)}`
            else if (!cutInizio && cutFine) return `${dizionario.al[code]} ${formatoDataCompatto(cutFine)}`
        }

        if (date.length == 1) {
            const data = { ...date[0] }

            data.data_inizio = data.data_inizio ? getUTCMidnightDate(data.data_inizio) : null
            data.data_fine = data.data_fine ? getUTCMidnightDate(data.data_fine) : null
            data.inizio_ricorrenza = data.inizio_ricorrenza ? getUTCMidnightDate(data.inizio_ricorrenza) : null
            data.fine_ricorrenza = data.fine_ricorrenza ? getUTCMidnightDate(data.fine_ricorrenza) : null

            if (data.data_fine && data.data_inizio && data.data_fine.getTime() < data.data_inizio.getTime()) return null
            if (data.ricorrenza == 'mai' && (!data.data_inizio || !data.data_fine)) return null

            data.settimana = data.settimana && data.settimana.length > 0 ? data.settimana.map((g: string) => parseInt(g)) : []
            data.numero_giorno = data.numero_giorno && data.numero_giorno.length > 0 ? data.numero_giorno.map((g: string) => parseInt(g)) : []
            data.mesi_esclusi = data.mesi_esclusi && data.mesi_esclusi.length > 0 ? data.mesi_esclusi.map((g: string) => parseInt(g)) : []

            switch (data.ricorrenza) {
                case 'mai':
                    if (data.data_inizio && data.data_fine && data.data_inizio.getTime() == data.data_fine.getTime()) {
                        return formatoDataLungo(data.data_inizio)
                    } else {
                        return `${dizionario.mdal[code]} ${formatoDataCompatto(data.data_inizio as Date)} ${dizionario.al[code]} ${formatoDataCompatto(data.data_fine as Date)}`
                    }
                case 'giornaliera':
                    let desc_giornaliero = dizionario.ognigiorno[code]
                    return desc_giornaliero
                case 'settimanale':
                    if (data.inizio_ricorrenza && data.fine_ricorrenza) return `${dizionario.mdal[code]} ${formatoDataCompatto(data.inizio_ricorrenza)} ${dizionario.al[code]} ${formatoDataCompatto(data.fine_ricorrenza)}`
                    else if (data.inizio_ricorrenza && !data.fine_ricorrenza) return `${dizionario.mdal[code]} ${formatoDataCompatto(data.inizio_ricorrenza)}`
                    else if (!data.inizio_ricorrenza && data.fine_ricorrenza) return `${dizionario.finoal[code]} ${formatoDataCompatto(data.fine_ricorrenza)}`
                    else {
                        const giorniSettimana = []

                        if (data.lunedi_aperto != 'chiuso') giorniSettimana.push(giorniLunghiPlurali[0])
                        if (data.martedi_aperto != 'chiuso') giorniSettimana.push(giorniLunghiPlurali[1])
                        if (data.mercoledi_aperto != 'chiuso') giorniSettimana.push(giorniLunghiPlurali[2])
                        if (data.giovedi_aperto != 'chiuso') giorniSettimana.push(giorniLunghiPlurali[3])
                        if (data.venerdi_aperto != 'chiuso') giorniSettimana.push(giorniLunghiPlurali[4])
                        if (data.sabato_aperto != 'chiuso') giorniSettimana.push(giorniLunghiPlurali[5])
                        if (data.domenica_aperto != 'chiuso') giorniSettimana.push(giorniLunghiPlurali[6])

                        if (giorniSettimana.length == 0) return null
                        else if (giorniSettimana.length == 1) return `${dizionario.ogni[code]} ${giorniSettimana[0]}`
                        else if (giorniSettimana.length == 2) return `${dizionario.ogni[code]} ${giorniSettimana[0]} ${dizionario.e[code]} ${giorniSettimana[1]}`
                        else if (giorniSettimana.length == 3) return `${dizionario.ogni[code]} ${giorniSettimana[0]}, ${giorniSettimana[1]} ${dizionario.e[code]} ${giorniSettimana[2]}`
                        else return `${dizionario.ogni[code]} ${giorniSettimana.join(', ')}`
                    }
                case 'mensile':
                    switch (data.ogni) {
                        case 'numero':
                            let desc_mensile_numero = `${dizionario.ogni[code]} ${data.numero_giorno.join(', ')} ${dizionario.delmese[code]}`
                            desc_mensile_numero = aggiungiDalAl(desc_mensile_numero, data)
                            // eccetto?
                            return desc_mensile_numero
                        case 'primo':
                        case 'secondo':
                        case 'terzo':
                        case 'quarto':
                        case 'ultimo':
                            let desc_mensile = ''
                            if (data.settimana.length == 1) {
                                desc_mensile = `${numeraliMese[data.ogni as 'primo' | 'secondo' | 'terzo' | 'quarto' | 'ultimo'].singolo} ${giorniLunghiPlurali[data.settimana[0] - 1]} ${dizionario.delmese[code]}`
                                if (data.settimana[0] == 7) desc_mensile = `${numeraliMese[data.ogni as 'primo' | 'secondo' | 'terzo' | 'quarto' | 'ultimo'].domenica} ${giorniLunghiPlurali[data.settimana[0] - 1]} ${dizionario.delmese[code]}`
                            } else {
                                desc_mensile = `${numeraliMese[data.ogni as 'primo' | 'secondo' | 'terzo' | 'quarto' | 'ultimo'].plurale} ${data.settimana.map((g: number) => giorniLunghiPlurali[g - 1]).join(', ')} ${dizionario.delmese[code]}`
                            }
                            desc_mensile = aggiungiDalAl(desc_mensile, data)
                            // eccetto?
                            return desc_mensile
                        default:
                            return null
                    }
                default:
                    return null
            }

        } else {
            if (date.every((d) => d.ricorrenza == 'mai')) {
                return `${date.length} date`
            } else return 'Date multiple'
        }
    }

    const verbalizzaSottotitoloSpettacolo = (date_spettacoli: any[]) => {
        if (date_spettacoli.length == 1) {
            return formatoDataLungo(new Date(date_spettacoli[0].data))
        } else if (date_spettacoli.length > 1) {
            const code = localeProperties.value.code as 'it' | 'en' | 'fr'
            return `${dizionario.mdal[code]} ${formatoDataCompatto(new Date(date_spettacoli[0].data))} ${dizionario.al[code]} ${formatoDataCompatto(new Date(date_spettacoli[date_spettacoli.length - 1].data))}`
        } else return null
    }

    const verbalizzaSottotitoloVisitaGuidata = (date_visite_guidate: any[]) => {

        const n = date_visite_guidate.length

        if (n === 0) return null
        else if (n === 1) return formatoDataLungo(new Date(date_visite_guidate[0].data))
        else {

            // Trova giorni unici nella lista di date
            const uniqueDayTimestamps = new Set<number>();
            date_visite_guidate.forEach(item => {
                const dateObj = new Date(item.data);
                dateObj.setHours(0, 0, 0, 0);
                uniqueDayTimestamps.add(dateObj.getTime());
            });

            // Ordina i giorni
            const sortedUniqueDayTimestamps = Array.from(uniqueDayTimestamps).sort((a, b) => a - b);
            const numberOfUniqueDays = sortedUniqueDayTimestamps.length;

            if (numberOfUniqueDays == 1) {
                return formatoDataLungo(new Date(date_visite_guidate[0].data))
            }
            else if (numberOfUniqueDays <= 3) {
                const formattedDays = sortedUniqueDayTimestamps.map(timestamp => formatoDataCompatto(new Date(timestamp)));
                return formattedDays.join(', ');
            }
            else {
                const code = localeProperties.value.code as 'it' | 'en' | 'fr';

                const dateSorted = [...date_visite_guidate].sort((a, b) =>
                    new Date(a.data).getTime() - new Date(b.data).getTime()
                );

                const firstDate = new Date(dateSorted[0].data);
                const lastDate = new Date(dateSorted[dateSorted.length - 1].data);

                return `${dizionario.mdal[code]} ${formatoDataCompatto(firstDate)} ${dizionario.al[code]} ${formatoDataCompatto(lastDate)}`;
            }
        }
    }

    const seEventoPassato = (date: Orario[], usa_orari_sede: boolean = false, data_inizio_orari_sede: string | null = null, data_fine_orari_sede: string | null = null) => {
        const dataOggi = getUTCMidnightDate(new Date())

        // Se vengono usati orari della sede controlla che oggi non sia fuori dal range
        if (usa_orari_sede === true) {
            const cutFine = data_fine_orari_sede ? getUTCMidnightDate(data_fine_orari_sede) : null
            if (cutFine && cutFine.getTime() < dataOggi.getTime()) return true
        }

        // Date è orari eventi oppure orari sede inclusi straordinari
        if (date && date.length > 0) {
            let flags = <boolean[]>[]

            for (const data of date) {

                const dataInizio = data.data_inizio ? getUTCMidnightDate(data.data_inizio) : null
                const dataFine = data.data_fine ? getUTCMidnightDate(data.data_fine) : null
                const fineRicorrenza = data.fine_ricorrenza ? getUTCMidnightDate(data.fine_ricorrenza) : null

                if (dataInizio && dataFine && dataFine.getTime() < dataInizio.getTime()) break
                if (data.ricorrenza == 'mai' && (!data.data_inizio || !data.data_fine)) break

                switch (data.ricorrenza) {
                    case 'mai':
                        if (dataFine && dataFine.getTime() < dataOggi.getTime()) flags.push(true)
                        else flags.push(false)
                        break;
                    case 'giornaliera':
                    case 'settimanale':
                    case 'mensile':
                        if (fineRicorrenza && fineRicorrenza.getTime() < dataOggi.getTime()) flags.push(true)
                        else flags.push(false)
                        break;
                }
            }

            return flags.every((f) => f === true)
        } else return false
    }
    const seAttivitaPassata = (date: Orario[]) => {

        if (date.length == 0) return false

        let flags = <boolean[]>[]
        const dataOggi = getUTCMidnightDate(new Date())

        for (const data of date) {

            const dataInizio = data.data_inizio ? getUTCMidnightDate(data.data_inizio) : null
            const dataFine = data.data_fine ? getUTCMidnightDate(data.data_fine) : null
            const fineRicorrenza = data.fine_ricorrenza ? getUTCMidnightDate(data.fine_ricorrenza) : null

            if (dataInizio && dataFine && dataFine.getTime() < dataInizio.getTime()) break
            if (data.ricorrenza == 'mai' && (!data.data_inizio || !data.data_fine)) break

            switch (data.ricorrenza) {
                case 'mai':
                    if (dataFine && dataFine.getTime() < dataOggi.getTime()) flags.push(true)
                    else flags.push(false)
                    break;
                case 'giornaliera':
                case 'settimanale':
                case 'mensile':
                    if (fineRicorrenza && fineRicorrenza.getTime() < dataOggi.getTime()) flags.push(true)
                    else flags.push(false)
                    break;
            }
        }

        return flags.every((f) => f === true)
    }

    const seSpettacoloPassato = (date: DataSpettacolo[]) => {

        let flags = <boolean[]>[]
        const dataOggi = getUTCMidnightDate(new Date())

        for (const data of date) {
            const dataSpettacolo = data.data ? getUTCMidnightDate(data.data) : null
            if (dataSpettacolo && dataSpettacolo.getTime() < dataOggi.getTime()) flags.push(true)
            else flags.push(false)
        }

        return flags.every((f) => f === true)
    }

    const seVisitaGuidataPassata = (date: DataVisitaGuidata[]) => {

        let flags = <boolean[]>[]
        const dataOggi = getUTCMidnightDate(new Date())

        for (const data of date) {
            const dataVisita = data.data ? getUTCMidnightDate(data.data) : null
            if (dataVisita && dataVisita.getTime() < dataOggi.getTime()) flags.push(true)
            else flags.push(false)
        }

        return flags.every((f) => f === true)
    }

    const sortEventiByOrario = (eventi: any[], collectionName: string, sort: 'asc' | 'desc') => {

        const eventiArray = <any[]>[]

        for (const e of eventi) {

            const listaCollection = e[collectionName] || []
            const listaSpettacoli = e['spettacoli_id'] || []
            const listaVisitaGuidate = e['visite_guidate_id'] || []

            const evento = { ...listaCollection, ...listaSpettacoli, ...listaVisitaGuidate }
            const dateEvento = <Date[]>[]

            if (evento.date) {
                for (const data of evento.date) {
                    switch (data.ricorrenza) {
                        case 'mai':
                            const dataInizio = data.data_inizio ? getUTCMidnightDate(data.data_inizio) : null
                            if (dataInizio) dateEvento.push(dataInizio)
                        case 'giornaliera':
                        case 'settimanale':
                        case 'mensile':
                            const inizioRicorrenza = data.inizio_ricorrenza ? getUTCMidnightDate(data.inizio_ricorrenza) : getUTCMidnightDate('2033-12-31')
                            if (inizioRicorrenza) dateEvento.push(inizioRicorrenza)
                    }
                }

                if (dateEvento.length > 0) {
                    evento.data_compare = dateEvento.sort((a, b) => a.getTime() - b.getTime())[0]
                } else {
                    evento.data_compare = getUTCMidnightDate('2033-12-31')
                }
            } else if (evento.date_spettacoli) {
                for (const data of evento.date_spettacoli) {
                    const dataSpettacolo = data.data ? getUTCMidnightDate(data.data) : null
                    if (dataSpettacolo) dateEvento.push(dataSpettacolo)
                }

                if (dateEvento.length > 0) {
                    evento.data_compare = dateEvento.sort((a, b) => a.getTime() - b.getTime())[0]
                } else {
                    evento.data_compare = getUTCMidnightDate('2033-12-31')
                }
            } else if (evento.date_visite_guidate) {
                for (const data of evento.date_visite_guidate) {
                    const dataVisita = data.data ? getUTCMidnightDate(data.data) : null
                    if (dataVisita) dateEvento.push(dataVisita)
                }

                if (dateEvento.length > 0) {
                    evento.data_compare = dateEvento.sort((a, b) => a.getTime() - b.getTime())[0]
                } else {
                    evento.data_compare = getUTCMidnightDate('2033-12-31')
                }
            } else {
                evento.data_compare = getUTCMidnightDate('2033-12-31')
            }

            eventiArray.push(evento)
        }

        if (sort == 'asc') return eventiArray.sort((a, b) => a.data_compare.getTime() - b.data_compare.getTime())
        else return eventiArray.sort((a, b) => b.data_compare.getTime() - a.data_compare.getTime())
    }
    const sortAttivitaByOrario = (eventi: any[], sort: 'asc' | 'desc') => {
        const eventiArray = <any[]>[]

        for (const evento of eventi) {
            const dateEvento = <Date[]>[]

            if (evento.date) {
                for (const data of evento.date) {
                    switch (data.ricorrenza) {
                        case 'mai':
                            const dataInizio = data.data_inizio ? getUTCMidnightDate(data.data_inizio) : null
                            if (dataInizio) dateEvento.push(dataInizio)
                        case 'giornaliera':
                        case 'settimanale':
                        case 'mensile':
                            const inizioRicorrenza = data.inizio_ricorrenza ? getUTCMidnightDate(data.inizio_ricorrenza) : getUTCMidnightDate('2033-12-31')
                            if (inizioRicorrenza) dateEvento.push(inizioRicorrenza)
                    }
                }

                if (dateEvento.length > 0) {
                    evento.data_compare = dateEvento.sort((a, b) => a.getTime() - b.getTime())[0]
                } else {
                    evento.data_compare = getUTCMidnightDate('2033-12-31')
                }
            }

            eventiArray.push(evento)
        }

        if (sort == 'asc') return eventiArray.sort((a, b) => a.data_compare.getTime() - b.data_compare.getTime())
        else return eventiArray.sort((a, b) => b.data_compare.getTime() - a.data_compare.getTime())
    }

    const pickerLocale = {
        it: {
            direction: 'ltr',
            format: 'dd/mm/yyyy',
            separator: ' - ',
            applyLabel: 'Applica',
            cancelLabel: 'Annulla',
            weekLabel: 'S',
            customRangeLabel: 'Range personalizzato',
            daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
            monthNames: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
            firstDay: 0
        },
        en: {
            direction: 'ltr',
            format: 'mm/dd/yyyy',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            firstDay: 0
        },
        fr: {
            direction: 'ltr',
            format: 'dd/mm/yyyy',
            separator: ' - ',
            applyLabel: 'Appliquer',
            cancelLabel: 'Annuler',
            weekLabel: 'W',
            customRangeLabel: 'Plage personnalisée',
            daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            monthNames: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jul', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
            firstDay: 0
        }
    }

    return {
        getUTCMidnightDate,

        verbalizzaSottotitolo,
        verbalizzaSottotitoloSpettacolo,
        verbalizzaSottotitoloVisitaGuidata,

        formatoDataLungo,
        formatoDataCompatto,
        formatoOra,
        formatoOraSenzaData,
        dataGiornoConOrario,
        dataGiornoConOrarioMobile,
        dataConOrario,
        dataSenzaOrario,
        dataCompletaSenzaOrario,
        dataSenzaAnno,

        seEventoPassato,
        seAttivitaPassata,
        seSpettacoloPassato,
        seVisitaGuidataPassata,

        sortEventiByOrario,
        sortAttivitaByOrario,

        giorniAbbreviati,
        giorniLunghi,
        giorniLunghiPlurali,
        nomiGiorni,
        nomiMesi,
        numeraliMese,
        pickerLocale,
        dizionario
    }
}