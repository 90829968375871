
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as _91id_93IfI9h63HycMeta } from "/app/pages/acquista/[tipo]/[id].vue?macro=true";
import { default as _91id_93stitSg8vpEMeta } from "/app/pages/acquista/bundle/[id].vue?macro=true";
import { default as _91id_93A8tXzWwDm2Meta } from "/app/pages/acquista/visite-guidate/[id].vue?macro=true";
import { default as agenda1WncwhWOayMeta } from "/app/pages/agenda.vue?macro=true";
import { default as carrellon5jYLIrO6hMeta } from "/app/pages/carrello.vue?macro=true";
import { default as cercaAUZ6zH48BaMeta } from "/app/pages/cerca.vue?macro=true";
import { default as index7ZtduOIoVXMeta } from "/app/pages/checkout/index.vue?macro=true";
import { default as _91ordine_935VcSZR05fyMeta } from "/app/pages/checkout/success/[ordine].vue?macro=true";
import { default as acquistaFwpOzbl6ROMeta } from "/app/pages/dashboard/armonia/acquista.vue?macro=true";
import { default as index9oLEqqqNklMeta } from "/app/pages/dashboard/armonia/calendario/[slug]/index.vue?macro=true";
import { default as prenotap4dUBVOUl7Meta } from "/app/pages/dashboard/armonia/calendario/[slug]/prenota.vue?macro=true";
import { default as indexRcw2PH7bN2Meta } from "/app/pages/dashboard/armonia/calendario/index.vue?macro=true";
import { default as gestisciZk9ZpqcIcPMeta } from "/app/pages/dashboard/armonia/gestisci.vue?macro=true";
import { default as index1VNdyWQorWMeta } from "/app/pages/dashboard/incoming/crea/index.vue?macro=true";
import { default as indexT8Y0gdHW5TMeta } from "/app/pages/dashboard/incoming/index.vue?macro=true";
import { default as _91id_93t26oeuMka9Meta } from "/app/pages/dashboard/incoming/modifica/[id].vue?macro=true";
import { default as registrazioneEaJCVxLaIUMeta } from "/app/pages/dashboard/incoming/registrazione.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as forgota8F5eQXX54Meta } from "/app/pages/dashboard/login/forgot.vue?macro=true";
import { default as indexsV371hMrJJMeta } from "/app/pages/dashboard/login/index.vue?macro=true";
import { default as resetRd0yIwNwoiMeta } from "/app/pages/dashboard/login/reset.vue?macro=true";
import { default as maintenanceJMPD0GOas0Meta } from "/app/pages/dashboard/maintenance.vue?macro=true";
import { default as medNp4MGjdp4Meta } from "/app/pages/dashboard/me.vue?macro=true";
import { default as indexYU4miowxFxMeta } from "/app/pages/dashboard/outdoor/crea/index.vue?macro=true";
import { default as indexzLCIToWhC9Meta } from "/app/pages/dashboard/outdoor/index.vue?macro=true";
import { default as _91id_93wpxXK2ZZgBMeta } from "/app/pages/dashboard/outdoor/modifica/[id].vue?macro=true";
import { default as registrazioneZ7COiKXyY7Meta } from "/app/pages/dashboard/outdoor/registrazione.vue?macro=true";
import { default as _91id_93x4Hjtb4mKMMeta } from "/app/pages/dashboard/profilo/card/abbonamenti-athena/[id].vue?macro=true";
import { default as indexBa1ZNvyJqsMeta } from "/app/pages/dashboard/profilo/card/index.vue?macro=true";
import { default as modificayNtncgyvYYMeta } from "/app/pages/dashboard/profilo/modifica.vue?macro=true";
import { default as index8mr6Ix6j9mMeta } from "/app/pages/dashboard/profilo/ordini/index.vue?macro=true";
import { default as indexJTUoe2qMyUMeta } from "/app/pages/dashboard/profilo/prenotazioni/index.vue?macro=true";
import { default as indexqmW7H2RBb2Meta } from "/app/pages/dashboard/promuovi-evento/index.vue?macro=true";
import { default as indexIWeqbjfSetMeta } from "/app/pages/dashboard/promuovi-ristorazione/index.vue?macro=true";
import { default as indexGcBmxLHDPjMeta } from "/app/pages/dashboard/registrazione/index.vue?macro=true";
import { default as invitoVYv0Urni41Meta } from "/app/pages/dashboard/registrazione/invito.vue?macro=true";
import { default as indexd8uSUCKnQzMeta } from "/app/pages/dashboard/ristorazione/crea/index.vue?macro=true";
import { default as indexf8qUhoRCf6Meta } from "/app/pages/dashboard/ristorazione/index.vue?macro=true";
import { default as _91id_93Z6WwMFADiHMeta } from "/app/pages/dashboard/ristorazione/modifica/[id].vue?macro=true";
import { default as _91id_933q1TRmEo9CMeta } from "/app/pages/l/attivita/[id].vue?macro=true";
import { default as _91id_93EsPVblCsp2Meta } from "/app/pages/l/eventi/[id].vue?macro=true";
import { default as _91id_932t5XCYePqRMeta } from "/app/pages/l/news_convention/[id].vue?macro=true";
import { default as _91id_937TLf2SinqjMeta } from "/app/pages/l/pacchetti/[id].vue?macro=true";
import { default as _91id_93ZqyNgHGAhRMeta } from "/app/pages/l/pagine/[id].vue?macro=true";
import { default as _91id_930NVituDPGqMeta } from "/app/pages/l/prodotti/[id].vue?macro=true";
import { default as _91id_9321WRiHsafkMeta } from "/app/pages/l/risorse/[id].vue?macro=true";
import { default as _91id_93tlYs8RmZ6tMeta } from "/app/pages/l/spettacoli/[id].vue?macro=true";
import { default as _91id_93RrY21UNsyqMeta } from "/app/pages/l/visite_guidate/[id].vue?macro=true";
import { default as _91id_93SuUG3QFD80Meta } from "/app/pages/node/[id].vue?macro=true";
import { default as _91id_93raJnUO4U6YMeta } from "/app/pages/preview/attivita/[id].vue?macro=true";
import { default as _91id_93btOQI1c7PHMeta } from "/app/pages/preview/eventi/[id].vue?macro=true";
import { default as _91id_93OmuLoaj4G8Meta } from "/app/pages/preview/pacchetti/[id].vue?macro=true";
import { default as _91id_9343C60H6OOAMeta } from "/app/pages/preview/pagine/[id].vue?macro=true";
import { default as _91id_93N8SjDNik31Meta } from "/app/pages/preview/prodotti/[id].vue?macro=true";
import { default as _91id_930CsGKToLHkMeta } from "/app/pages/preview/risorse/[id].vue?macro=true";
import { default as _91id_93hm6f9ngwCgMeta } from "/app/pages/preview/visite-guidate/[id].vue?macro=true";
import { default as invitohhQEFSo506Meta } from "/app/pages/registrazione/invito.vue?macro=true";
import { default as traduzione_45mancanteyXI6jHIkEdMeta } from "/app/pages/traduzione-mancante.vue?macro=true";
import { default as indexcyzlsj6P9EMeta } from "/app/pages/visita/index.vue?macro=true";
import { default as torino_45piemonte_45cardwkmzCcRDRBMeta } from "/app/pages/visita/pianifica-la-tua-visita/torino-piemonte-card.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "slug___it",
    path: "/it/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "acquista-tipo-id___it",
    path: "/it/acquista/:tipo()/:id()",
    meta: _91id_93IfI9h63HycMeta || {},
    component: () => import("/app/pages/acquista/[tipo]/[id].vue")
  },
  {
    name: "acquista-tipo-id___en",
    path: "/en/acquista/:tipo()/:id()",
    meta: _91id_93IfI9h63HycMeta || {},
    component: () => import("/app/pages/acquista/[tipo]/[id].vue")
  },
  {
    name: "acquista-tipo-id___fr",
    path: "/fr/acquista/:tipo()/:id()",
    meta: _91id_93IfI9h63HycMeta || {},
    component: () => import("/app/pages/acquista/[tipo]/[id].vue")
  },
  {
    name: "acquista-bundle-id___it",
    path: "/it/acquista/bundle/:id()",
    component: () => import("/app/pages/acquista/bundle/[id].vue")
  },
  {
    name: "acquista-bundle-id___en",
    path: "/en/acquista/bundle/:id()",
    component: () => import("/app/pages/acquista/bundle/[id].vue")
  },
  {
    name: "acquista-bundle-id___fr",
    path: "/fr/acquista/bundle/:id()",
    component: () => import("/app/pages/acquista/bundle/[id].vue")
  },
  {
    name: "acquista-visite-guidate-id___it",
    path: "/it/acquista/visite-guidate/:id()",
    component: () => import("/app/pages/acquista/visite-guidate/[id].vue")
  },
  {
    name: "acquista-visite-guidate-id___en",
    path: "/en/acquista/visite-guidate/:id()",
    component: () => import("/app/pages/acquista/visite-guidate/[id].vue")
  },
  {
    name: "acquista-visite-guidate-id___fr",
    path: "/fr/acquista/visite-guidate/:id()",
    component: () => import("/app/pages/acquista/visite-guidate/[id].vue")
  },
  {
    name: "agenda___it",
    path: "/it/agenda",
    meta: agenda1WncwhWOayMeta || {},
    component: () => import("/app/pages/agenda.vue")
  },
  {
    name: "agenda___en",
    path: "/en/agenda",
    meta: agenda1WncwhWOayMeta || {},
    component: () => import("/app/pages/agenda.vue")
  },
  {
    name: "agenda___fr",
    path: "/fr/agenda",
    meta: agenda1WncwhWOayMeta || {},
    component: () => import("/app/pages/agenda.vue")
  },
  {
    name: "carrello___it",
    path: "/it/carrello",
    meta: carrellon5jYLIrO6hMeta || {},
    component: () => import("/app/pages/carrello.vue")
  },
  {
    name: "carrello___en",
    path: "/en/carrello",
    meta: carrellon5jYLIrO6hMeta || {},
    component: () => import("/app/pages/carrello.vue")
  },
  {
    name: "carrello___fr",
    path: "/fr/carrello",
    meta: carrellon5jYLIrO6hMeta || {},
    component: () => import("/app/pages/carrello.vue")
  },
  {
    name: "cerca___it",
    path: "/it/cerca",
    component: () => import("/app/pages/cerca.vue")
  },
  {
    name: "cerca___en",
    path: "/en/cerca",
    component: () => import("/app/pages/cerca.vue")
  },
  {
    name: "cerca___fr",
    path: "/fr/cerca",
    component: () => import("/app/pages/cerca.vue")
  },
  {
    name: "checkout___it",
    path: "/it/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___fr",
    path: "/fr/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "checkout-success-ordine___it",
    path: "/it/checkout/success/:ordine()",
    component: () => import("/app/pages/checkout/success/[ordine].vue")
  },
  {
    name: "checkout-success-ordine___en",
    path: "/en/checkout/success/:ordine()",
    component: () => import("/app/pages/checkout/success/[ordine].vue")
  },
  {
    name: "checkout-success-ordine___fr",
    path: "/fr/checkout/success/:ordine()",
    component: () => import("/app/pages/checkout/success/[ordine].vue")
  },
  {
    name: "dashboard-armonia-acquista___it",
    path: "/it/dashboard/armonia/acquista",
    component: () => import("/app/pages/dashboard/armonia/acquista.vue")
  },
  {
    name: "dashboard-armonia-acquista___en",
    path: "/en/dashboard/armonia/acquista",
    component: () => import("/app/pages/dashboard/armonia/acquista.vue")
  },
  {
    name: "dashboard-armonia-acquista___fr",
    path: "/fr/dashboard/armonia/acquista",
    component: () => import("/app/pages/dashboard/armonia/acquista.vue")
  },
  {
    name: "dashboard-armonia-calendario-slug___it",
    path: "/it/dashboard/armonia/calendario/:slug()",
    meta: index9oLEqqqNklMeta || {},
    component: () => import("/app/pages/dashboard/armonia/calendario/[slug]/index.vue")
  },
  {
    name: "dashboard-armonia-calendario-slug___en",
    path: "/en/dashboard/armonia/calendario/:slug()",
    meta: index9oLEqqqNklMeta || {},
    component: () => import("/app/pages/dashboard/armonia/calendario/[slug]/index.vue")
  },
  {
    name: "dashboard-armonia-calendario-slug___fr",
    path: "/fr/dashboard/armonia/calendario/:slug()",
    meta: index9oLEqqqNklMeta || {},
    component: () => import("/app/pages/dashboard/armonia/calendario/[slug]/index.vue")
  },
  {
    name: "dashboard-armonia-calendario-slug-prenota___it",
    path: "/it/dashboard/armonia/calendario/:slug()/prenota",
    meta: prenotap4dUBVOUl7Meta || {},
    component: () => import("/app/pages/dashboard/armonia/calendario/[slug]/prenota.vue")
  },
  {
    name: "dashboard-armonia-calendario-slug-prenota___en",
    path: "/en/dashboard/armonia/calendario/:slug()/prenota",
    meta: prenotap4dUBVOUl7Meta || {},
    component: () => import("/app/pages/dashboard/armonia/calendario/[slug]/prenota.vue")
  },
  {
    name: "dashboard-armonia-calendario-slug-prenota___fr",
    path: "/fr/dashboard/armonia/calendario/:slug()/prenota",
    meta: prenotap4dUBVOUl7Meta || {},
    component: () => import("/app/pages/dashboard/armonia/calendario/[slug]/prenota.vue")
  },
  {
    name: "dashboard-armonia-calendario___it",
    path: "/it/dashboard/armonia/calendario",
    meta: indexRcw2PH7bN2Meta || {},
    component: () => import("/app/pages/dashboard/armonia/calendario/index.vue")
  },
  {
    name: "dashboard-armonia-calendario___en",
    path: "/en/dashboard/armonia/calendario",
    meta: indexRcw2PH7bN2Meta || {},
    component: () => import("/app/pages/dashboard/armonia/calendario/index.vue")
  },
  {
    name: "dashboard-armonia-calendario___fr",
    path: "/fr/dashboard/armonia/calendario",
    meta: indexRcw2PH7bN2Meta || {},
    component: () => import("/app/pages/dashboard/armonia/calendario/index.vue")
  },
  {
    name: "dashboard-armonia-gestisci___it",
    path: "/it/dashboard/armonia/gestisci",
    meta: gestisciZk9ZpqcIcPMeta || {},
    component: () => import("/app/pages/dashboard/armonia/gestisci.vue")
  },
  {
    name: "dashboard-armonia-gestisci___en",
    path: "/en/dashboard/armonia/gestisci",
    meta: gestisciZk9ZpqcIcPMeta || {},
    component: () => import("/app/pages/dashboard/armonia/gestisci.vue")
  },
  {
    name: "dashboard-armonia-gestisci___fr",
    path: "/fr/dashboard/armonia/gestisci",
    meta: gestisciZk9ZpqcIcPMeta || {},
    component: () => import("/app/pages/dashboard/armonia/gestisci.vue")
  },
  {
    name: "dashboard-incoming-crea___it",
    path: "/it/dashboard/incoming/crea",
    meta: index1VNdyWQorWMeta || {},
    component: () => import("/app/pages/dashboard/incoming/crea/index.vue")
  },
  {
    name: "dashboard-incoming-crea___en",
    path: "/en/dashboard/incoming/crea",
    meta: index1VNdyWQorWMeta || {},
    component: () => import("/app/pages/dashboard/incoming/crea/index.vue")
  },
  {
    name: "dashboard-incoming-crea___fr",
    path: "/fr/dashboard/incoming/crea",
    meta: index1VNdyWQorWMeta || {},
    component: () => import("/app/pages/dashboard/incoming/crea/index.vue")
  },
  {
    name: "dashboard-incoming___it",
    path: "/it/dashboard/incoming",
    meta: indexT8Y0gdHW5TMeta || {},
    component: () => import("/app/pages/dashboard/incoming/index.vue")
  },
  {
    name: "dashboard-incoming___en",
    path: "/en/dashboard/incoming",
    meta: indexT8Y0gdHW5TMeta || {},
    component: () => import("/app/pages/dashboard/incoming/index.vue")
  },
  {
    name: "dashboard-incoming___fr",
    path: "/fr/dashboard/incoming",
    meta: indexT8Y0gdHW5TMeta || {},
    component: () => import("/app/pages/dashboard/incoming/index.vue")
  },
  {
    name: "dashboard-incoming-modifica-id___it",
    path: "/it/dashboard/incoming/modifica/:id()",
    meta: _91id_93t26oeuMka9Meta || {},
    component: () => import("/app/pages/dashboard/incoming/modifica/[id].vue")
  },
  {
    name: "dashboard-incoming-modifica-id___en",
    path: "/en/dashboard/incoming/modifica/:id()",
    meta: _91id_93t26oeuMka9Meta || {},
    component: () => import("/app/pages/dashboard/incoming/modifica/[id].vue")
  },
  {
    name: "dashboard-incoming-modifica-id___fr",
    path: "/fr/dashboard/incoming/modifica/:id()",
    meta: _91id_93t26oeuMka9Meta || {},
    component: () => import("/app/pages/dashboard/incoming/modifica/[id].vue")
  },
  {
    name: "dashboard-incoming-registrazione___it",
    path: "/it/dashboard/incoming/registrazione",
    meta: registrazioneEaJCVxLaIUMeta || {},
    component: () => import("/app/pages/dashboard/incoming/registrazione.vue")
  },
  {
    name: "dashboard-incoming-registrazione___en",
    path: "/en/dashboard/incoming/registrazione",
    meta: registrazioneEaJCVxLaIUMeta || {},
    component: () => import("/app/pages/dashboard/incoming/registrazione.vue")
  },
  {
    name: "dashboard-incoming-registrazione___fr",
    path: "/fr/dashboard/incoming/registrazione",
    meta: registrazioneEaJCVxLaIUMeta || {},
    component: () => import("/app/pages/dashboard/incoming/registrazione.vue")
  },
  {
    name: "dashboard___it",
    path: "/it/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    component: () => import("/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___en",
    path: "/en/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    component: () => import("/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___fr",
    path: "/fr/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    component: () => import("/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-login-forgot___it",
    path: "/it/dashboard/login/forgot",
    component: () => import("/app/pages/dashboard/login/forgot.vue")
  },
  {
    name: "dashboard-login-forgot___en",
    path: "/en/dashboard/login/forgot",
    component: () => import("/app/pages/dashboard/login/forgot.vue")
  },
  {
    name: "dashboard-login-forgot___fr",
    path: "/fr/dashboard/login/forgot",
    component: () => import("/app/pages/dashboard/login/forgot.vue")
  },
  {
    name: "dashboard-login___it",
    path: "/it/dashboard/login",
    component: () => import("/app/pages/dashboard/login/index.vue")
  },
  {
    name: "dashboard-login___en",
    path: "/en/dashboard/login",
    component: () => import("/app/pages/dashboard/login/index.vue")
  },
  {
    name: "dashboard-login___fr",
    path: "/fr/dashboard/login",
    component: () => import("/app/pages/dashboard/login/index.vue")
  },
  {
    name: "dashboard-login-reset___it",
    path: "/it/dashboard/login/reset",
    meta: resetRd0yIwNwoiMeta || {},
    component: () => import("/app/pages/dashboard/login/reset.vue")
  },
  {
    name: "dashboard-login-reset___en",
    path: "/en/dashboard/login/reset",
    meta: resetRd0yIwNwoiMeta || {},
    component: () => import("/app/pages/dashboard/login/reset.vue")
  },
  {
    name: "dashboard-login-reset___fr",
    path: "/fr/dashboard/login/reset",
    meta: resetRd0yIwNwoiMeta || {},
    component: () => import("/app/pages/dashboard/login/reset.vue")
  },
  {
    name: "dashboard-maintenance___it",
    path: "/it/dashboard/maintenance",
    meta: maintenanceJMPD0GOas0Meta || {},
    component: () => import("/app/pages/dashboard/maintenance.vue")
  },
  {
    name: "dashboard-maintenance___en",
    path: "/en/dashboard/maintenance",
    meta: maintenanceJMPD0GOas0Meta || {},
    component: () => import("/app/pages/dashboard/maintenance.vue")
  },
  {
    name: "dashboard-maintenance___fr",
    path: "/fr/dashboard/maintenance",
    meta: maintenanceJMPD0GOas0Meta || {},
    component: () => import("/app/pages/dashboard/maintenance.vue")
  },
  {
    name: "dashboard-me___it",
    path: "/it/dashboard/me",
    meta: medNp4MGjdp4Meta || {},
    component: () => import("/app/pages/dashboard/me.vue")
  },
  {
    name: "dashboard-me___en",
    path: "/en/dashboard/me",
    meta: medNp4MGjdp4Meta || {},
    component: () => import("/app/pages/dashboard/me.vue")
  },
  {
    name: "dashboard-me___fr",
    path: "/fr/dashboard/me",
    meta: medNp4MGjdp4Meta || {},
    component: () => import("/app/pages/dashboard/me.vue")
  },
  {
    name: "dashboard-outdoor-crea___it",
    path: "/it/dashboard/outdoor/crea",
    meta: indexYU4miowxFxMeta || {},
    component: () => import("/app/pages/dashboard/outdoor/crea/index.vue")
  },
  {
    name: "dashboard-outdoor-crea___en",
    path: "/en/dashboard/outdoor/crea",
    meta: indexYU4miowxFxMeta || {},
    component: () => import("/app/pages/dashboard/outdoor/crea/index.vue")
  },
  {
    name: "dashboard-outdoor-crea___fr",
    path: "/fr/dashboard/outdoor/crea",
    meta: indexYU4miowxFxMeta || {},
    component: () => import("/app/pages/dashboard/outdoor/crea/index.vue")
  },
  {
    name: "dashboard-outdoor___it",
    path: "/it/dashboard/outdoor",
    meta: indexzLCIToWhC9Meta || {},
    component: () => import("/app/pages/dashboard/outdoor/index.vue")
  },
  {
    name: "dashboard-outdoor___en",
    path: "/en/dashboard/outdoor",
    meta: indexzLCIToWhC9Meta || {},
    component: () => import("/app/pages/dashboard/outdoor/index.vue")
  },
  {
    name: "dashboard-outdoor___fr",
    path: "/fr/dashboard/outdoor",
    meta: indexzLCIToWhC9Meta || {},
    component: () => import("/app/pages/dashboard/outdoor/index.vue")
  },
  {
    name: "dashboard-outdoor-modifica-id___it",
    path: "/it/dashboard/outdoor/modifica/:id()",
    meta: _91id_93wpxXK2ZZgBMeta || {},
    component: () => import("/app/pages/dashboard/outdoor/modifica/[id].vue")
  },
  {
    name: "dashboard-outdoor-modifica-id___en",
    path: "/en/dashboard/outdoor/modifica/:id()",
    meta: _91id_93wpxXK2ZZgBMeta || {},
    component: () => import("/app/pages/dashboard/outdoor/modifica/[id].vue")
  },
  {
    name: "dashboard-outdoor-modifica-id___fr",
    path: "/fr/dashboard/outdoor/modifica/:id()",
    meta: _91id_93wpxXK2ZZgBMeta || {},
    component: () => import("/app/pages/dashboard/outdoor/modifica/[id].vue")
  },
  {
    name: "dashboard-outdoor-registrazione___it",
    path: "/it/dashboard/outdoor/registrazione",
    meta: registrazioneZ7COiKXyY7Meta || {},
    component: () => import("/app/pages/dashboard/outdoor/registrazione.vue")
  },
  {
    name: "dashboard-outdoor-registrazione___en",
    path: "/en/dashboard/outdoor/registrazione",
    meta: registrazioneZ7COiKXyY7Meta || {},
    component: () => import("/app/pages/dashboard/outdoor/registrazione.vue")
  },
  {
    name: "dashboard-outdoor-registrazione___fr",
    path: "/fr/dashboard/outdoor/registrazione",
    meta: registrazioneZ7COiKXyY7Meta || {},
    component: () => import("/app/pages/dashboard/outdoor/registrazione.vue")
  },
  {
    name: "dashboard-profilo-card-abbonamenti-athena-id___it",
    path: "/it/dashboard/profilo/card/abbonamenti-athena/:id()",
    meta: _91id_93x4Hjtb4mKMMeta || {},
    component: () => import("/app/pages/dashboard/profilo/card/abbonamenti-athena/[id].vue")
  },
  {
    name: "dashboard-profilo-card-abbonamenti-athena-id___en",
    path: "/en/dashboard/profilo/card/abbonamenti-athena/:id()",
    meta: _91id_93x4Hjtb4mKMMeta || {},
    component: () => import("/app/pages/dashboard/profilo/card/abbonamenti-athena/[id].vue")
  },
  {
    name: "dashboard-profilo-card-abbonamenti-athena-id___fr",
    path: "/fr/dashboard/profilo/card/abbonamenti-athena/:id()",
    meta: _91id_93x4Hjtb4mKMMeta || {},
    component: () => import("/app/pages/dashboard/profilo/card/abbonamenti-athena/[id].vue")
  },
  {
    name: "dashboard-profilo-card___it",
    path: "/it/dashboard/profilo/card",
    meta: indexBa1ZNvyJqsMeta || {},
    component: () => import("/app/pages/dashboard/profilo/card/index.vue")
  },
  {
    name: "dashboard-profilo-card___en",
    path: "/en/dashboard/profilo/card",
    meta: indexBa1ZNvyJqsMeta || {},
    component: () => import("/app/pages/dashboard/profilo/card/index.vue")
  },
  {
    name: "dashboard-profilo-card___fr",
    path: "/fr/dashboard/profilo/card",
    meta: indexBa1ZNvyJqsMeta || {},
    component: () => import("/app/pages/dashboard/profilo/card/index.vue")
  },
  {
    name: "dashboard-profilo-modifica___it",
    path: "/it/dashboard/profilo/modifica",
    meta: modificayNtncgyvYYMeta || {},
    component: () => import("/app/pages/dashboard/profilo/modifica.vue")
  },
  {
    name: "dashboard-profilo-modifica___en",
    path: "/en/dashboard/profilo/modifica",
    meta: modificayNtncgyvYYMeta || {},
    component: () => import("/app/pages/dashboard/profilo/modifica.vue")
  },
  {
    name: "dashboard-profilo-modifica___fr",
    path: "/fr/dashboard/profilo/modifica",
    meta: modificayNtncgyvYYMeta || {},
    component: () => import("/app/pages/dashboard/profilo/modifica.vue")
  },
  {
    name: "dashboard-profilo-ordini___it",
    path: "/it/dashboard/profilo/ordini",
    meta: index8mr6Ix6j9mMeta || {},
    component: () => import("/app/pages/dashboard/profilo/ordini/index.vue")
  },
  {
    name: "dashboard-profilo-ordini___en",
    path: "/en/dashboard/profilo/ordini",
    meta: index8mr6Ix6j9mMeta || {},
    component: () => import("/app/pages/dashboard/profilo/ordini/index.vue")
  },
  {
    name: "dashboard-profilo-ordini___fr",
    path: "/fr/dashboard/profilo/ordini",
    meta: index8mr6Ix6j9mMeta || {},
    component: () => import("/app/pages/dashboard/profilo/ordini/index.vue")
  },
  {
    name: "dashboard-profilo-prenotazioni___it",
    path: "/it/dashboard/profilo/prenotazioni",
    meta: indexJTUoe2qMyUMeta || {},
    component: () => import("/app/pages/dashboard/profilo/prenotazioni/index.vue")
  },
  {
    name: "dashboard-profilo-prenotazioni___en",
    path: "/en/dashboard/profilo/prenotazioni",
    meta: indexJTUoe2qMyUMeta || {},
    component: () => import("/app/pages/dashboard/profilo/prenotazioni/index.vue")
  },
  {
    name: "dashboard-profilo-prenotazioni___fr",
    path: "/fr/dashboard/profilo/prenotazioni",
    meta: indexJTUoe2qMyUMeta || {},
    component: () => import("/app/pages/dashboard/profilo/prenotazioni/index.vue")
  },
  {
    name: "dashboard-promuovi-evento___it",
    path: "/it/dashboard/promuovi-evento",
    meta: indexqmW7H2RBb2Meta || {},
    component: () => import("/app/pages/dashboard/promuovi-evento/index.vue")
  },
  {
    name: "dashboard-promuovi-evento___en",
    path: "/en/dashboard/promuovi-evento",
    meta: indexqmW7H2RBb2Meta || {},
    component: () => import("/app/pages/dashboard/promuovi-evento/index.vue")
  },
  {
    name: "dashboard-promuovi-evento___fr",
    path: "/fr/dashboard/promuovi-evento",
    meta: indexqmW7H2RBb2Meta || {},
    component: () => import("/app/pages/dashboard/promuovi-evento/index.vue")
  },
  {
    name: "dashboard-promuovi-ristorazione___it",
    path: "/it/dashboard/promuovi-ristorazione",
    meta: indexIWeqbjfSetMeta || {},
    component: () => import("/app/pages/dashboard/promuovi-ristorazione/index.vue")
  },
  {
    name: "dashboard-promuovi-ristorazione___en",
    path: "/en/dashboard/promuovi-ristorazione",
    meta: indexIWeqbjfSetMeta || {},
    component: () => import("/app/pages/dashboard/promuovi-ristorazione/index.vue")
  },
  {
    name: "dashboard-promuovi-ristorazione___fr",
    path: "/fr/dashboard/promuovi-ristorazione",
    meta: indexIWeqbjfSetMeta || {},
    component: () => import("/app/pages/dashboard/promuovi-ristorazione/index.vue")
  },
  {
    name: "dashboard-registrazione___it",
    path: "/it/dashboard/registrazione",
    component: () => import("/app/pages/dashboard/registrazione/index.vue")
  },
  {
    name: "dashboard-registrazione___en",
    path: "/en/dashboard/registrazione",
    component: () => import("/app/pages/dashboard/registrazione/index.vue")
  },
  {
    name: "dashboard-registrazione___fr",
    path: "/fr/dashboard/registrazione",
    component: () => import("/app/pages/dashboard/registrazione/index.vue")
  },
  {
    name: "dashboard-registrazione-invito___it",
    path: "/it/dashboard/registrazione/invito",
    meta: invitoVYv0Urni41Meta || {},
    component: () => import("/app/pages/dashboard/registrazione/invito.vue")
  },
  {
    name: "dashboard-registrazione-invito___en",
    path: "/en/dashboard/registrazione/invito",
    meta: invitoVYv0Urni41Meta || {},
    component: () => import("/app/pages/dashboard/registrazione/invito.vue")
  },
  {
    name: "dashboard-registrazione-invito___fr",
    path: "/fr/dashboard/registrazione/invito",
    meta: invitoVYv0Urni41Meta || {},
    component: () => import("/app/pages/dashboard/registrazione/invito.vue")
  },
  {
    name: "dashboard-ristorazione-crea___it",
    path: "/it/dashboard/ristorazione/crea",
    meta: indexd8uSUCKnQzMeta || {},
    component: () => import("/app/pages/dashboard/ristorazione/crea/index.vue")
  },
  {
    name: "dashboard-ristorazione-crea___en",
    path: "/en/dashboard/ristorazione/crea",
    meta: indexd8uSUCKnQzMeta || {},
    component: () => import("/app/pages/dashboard/ristorazione/crea/index.vue")
  },
  {
    name: "dashboard-ristorazione-crea___fr",
    path: "/fr/dashboard/ristorazione/crea",
    meta: indexd8uSUCKnQzMeta || {},
    component: () => import("/app/pages/dashboard/ristorazione/crea/index.vue")
  },
  {
    name: "dashboard-ristorazione___it",
    path: "/it/dashboard/ristorazione",
    meta: indexf8qUhoRCf6Meta || {},
    component: () => import("/app/pages/dashboard/ristorazione/index.vue")
  },
  {
    name: "dashboard-ristorazione___en",
    path: "/en/dashboard/ristorazione",
    meta: indexf8qUhoRCf6Meta || {},
    component: () => import("/app/pages/dashboard/ristorazione/index.vue")
  },
  {
    name: "dashboard-ristorazione___fr",
    path: "/fr/dashboard/ristorazione",
    meta: indexf8qUhoRCf6Meta || {},
    component: () => import("/app/pages/dashboard/ristorazione/index.vue")
  },
  {
    name: "dashboard-ristorazione-modifica-id___it",
    path: "/it/dashboard/ristorazione/modifica/:id()",
    meta: _91id_93Z6WwMFADiHMeta || {},
    component: () => import("/app/pages/dashboard/ristorazione/modifica/[id].vue")
  },
  {
    name: "dashboard-ristorazione-modifica-id___en",
    path: "/en/dashboard/ristorazione/modifica/:id()",
    meta: _91id_93Z6WwMFADiHMeta || {},
    component: () => import("/app/pages/dashboard/ristorazione/modifica/[id].vue")
  },
  {
    name: "dashboard-ristorazione-modifica-id___fr",
    path: "/fr/dashboard/ristorazione/modifica/:id()",
    meta: _91id_93Z6WwMFADiHMeta || {},
    component: () => import("/app/pages/dashboard/ristorazione/modifica/[id].vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "l-attivita-id___it",
    path: "/it/l/attivita/:id()",
    component: () => import("/app/pages/l/attivita/[id].vue")
  },
  {
    name: "l-attivita-id___en",
    path: "/en/l/attivita/:id()",
    component: () => import("/app/pages/l/attivita/[id].vue")
  },
  {
    name: "l-attivita-id___fr",
    path: "/fr/l/attivita/:id()",
    component: () => import("/app/pages/l/attivita/[id].vue")
  },
  {
    name: "l-eventi-id___it",
    path: "/it/l/eventi/:id()",
    component: () => import("/app/pages/l/eventi/[id].vue")
  },
  {
    name: "l-eventi-id___en",
    path: "/en/l/eventi/:id()",
    component: () => import("/app/pages/l/eventi/[id].vue")
  },
  {
    name: "l-eventi-id___fr",
    path: "/fr/l/eventi/:id()",
    component: () => import("/app/pages/l/eventi/[id].vue")
  },
  {
    name: "l-news_convention-id___it",
    path: "/it/l/news_convention/:id()",
    component: () => import("/app/pages/l/news_convention/[id].vue")
  },
  {
    name: "l-news_convention-id___en",
    path: "/en/l/news_convention/:id()",
    component: () => import("/app/pages/l/news_convention/[id].vue")
  },
  {
    name: "l-news_convention-id___fr",
    path: "/fr/l/news_convention/:id()",
    component: () => import("/app/pages/l/news_convention/[id].vue")
  },
  {
    name: "l-pacchetti-id___it",
    path: "/it/l/pacchetti/:id()",
    component: () => import("/app/pages/l/pacchetti/[id].vue")
  },
  {
    name: "l-pacchetti-id___en",
    path: "/en/l/pacchetti/:id()",
    component: () => import("/app/pages/l/pacchetti/[id].vue")
  },
  {
    name: "l-pacchetti-id___fr",
    path: "/fr/l/pacchetti/:id()",
    component: () => import("/app/pages/l/pacchetti/[id].vue")
  },
  {
    name: "l-pagine-id___it",
    path: "/it/l/pagine/:id()",
    component: () => import("/app/pages/l/pagine/[id].vue")
  },
  {
    name: "l-pagine-id___en",
    path: "/en/l/pagine/:id()",
    component: () => import("/app/pages/l/pagine/[id].vue")
  },
  {
    name: "l-pagine-id___fr",
    path: "/fr/l/pagine/:id()",
    component: () => import("/app/pages/l/pagine/[id].vue")
  },
  {
    name: "l-prodotti-id___it",
    path: "/it/l/prodotti/:id()",
    component: () => import("/app/pages/l/prodotti/[id].vue")
  },
  {
    name: "l-prodotti-id___en",
    path: "/en/l/prodotti/:id()",
    component: () => import("/app/pages/l/prodotti/[id].vue")
  },
  {
    name: "l-prodotti-id___fr",
    path: "/fr/l/prodotti/:id()",
    component: () => import("/app/pages/l/prodotti/[id].vue")
  },
  {
    name: "l-risorse-id___it",
    path: "/it/l/risorse/:id()",
    component: () => import("/app/pages/l/risorse/[id].vue")
  },
  {
    name: "l-risorse-id___en",
    path: "/en/l/risorse/:id()",
    component: () => import("/app/pages/l/risorse/[id].vue")
  },
  {
    name: "l-risorse-id___fr",
    path: "/fr/l/risorse/:id()",
    component: () => import("/app/pages/l/risorse/[id].vue")
  },
  {
    name: "l-spettacoli-id___it",
    path: "/it/l/spettacoli/:id()",
    component: () => import("/app/pages/l/spettacoli/[id].vue")
  },
  {
    name: "l-spettacoli-id___en",
    path: "/en/l/spettacoli/:id()",
    component: () => import("/app/pages/l/spettacoli/[id].vue")
  },
  {
    name: "l-spettacoli-id___fr",
    path: "/fr/l/spettacoli/:id()",
    component: () => import("/app/pages/l/spettacoli/[id].vue")
  },
  {
    name: "l-visite_guidate-id___it",
    path: "/it/l/visite_guidate/:id()",
    component: () => import("/app/pages/l/visite_guidate/[id].vue")
  },
  {
    name: "l-visite_guidate-id___en",
    path: "/en/l/visite_guidate/:id()",
    component: () => import("/app/pages/l/visite_guidate/[id].vue")
  },
  {
    name: "l-visite_guidate-id___fr",
    path: "/fr/l/visite_guidate/:id()",
    component: () => import("/app/pages/l/visite_guidate/[id].vue")
  },
  {
    name: "node-id___it",
    path: "/it/node/:id()",
    component: () => import("/app/pages/node/[id].vue")
  },
  {
    name: "node-id___en",
    path: "/en/node/:id()",
    component: () => import("/app/pages/node/[id].vue")
  },
  {
    name: "node-id___fr",
    path: "/fr/node/:id()",
    component: () => import("/app/pages/node/[id].vue")
  },
  {
    name: "preview-attivita-id___it",
    path: "/it/preview/attivita/:id()",
    component: () => import("/app/pages/preview/attivita/[id].vue")
  },
  {
    name: "preview-attivita-id___en",
    path: "/en/preview/attivita/:id()",
    component: () => import("/app/pages/preview/attivita/[id].vue")
  },
  {
    name: "preview-attivita-id___fr",
    path: "/fr/preview/attivita/:id()",
    component: () => import("/app/pages/preview/attivita/[id].vue")
  },
  {
    name: "preview-eventi-id___it",
    path: "/it/preview/eventi/:id()",
    component: () => import("/app/pages/preview/eventi/[id].vue")
  },
  {
    name: "preview-eventi-id___en",
    path: "/en/preview/eventi/:id()",
    component: () => import("/app/pages/preview/eventi/[id].vue")
  },
  {
    name: "preview-eventi-id___fr",
    path: "/fr/preview/eventi/:id()",
    component: () => import("/app/pages/preview/eventi/[id].vue")
  },
  {
    name: "preview-pacchetti-id___it",
    path: "/it/preview/pacchetti/:id()",
    component: () => import("/app/pages/preview/pacchetti/[id].vue")
  },
  {
    name: "preview-pacchetti-id___en",
    path: "/en/preview/pacchetti/:id()",
    component: () => import("/app/pages/preview/pacchetti/[id].vue")
  },
  {
    name: "preview-pacchetti-id___fr",
    path: "/fr/preview/pacchetti/:id()",
    component: () => import("/app/pages/preview/pacchetti/[id].vue")
  },
  {
    name: "preview-pagine-id___it",
    path: "/it/preview/pagine/:id()",
    component: () => import("/app/pages/preview/pagine/[id].vue")
  },
  {
    name: "preview-pagine-id___en",
    path: "/en/preview/pagine/:id()",
    component: () => import("/app/pages/preview/pagine/[id].vue")
  },
  {
    name: "preview-pagine-id___fr",
    path: "/fr/preview/pagine/:id()",
    component: () => import("/app/pages/preview/pagine/[id].vue")
  },
  {
    name: "preview-prodotti-id___it",
    path: "/it/preview/prodotti/:id()",
    component: () => import("/app/pages/preview/prodotti/[id].vue")
  },
  {
    name: "preview-prodotti-id___en",
    path: "/en/preview/prodotti/:id()",
    component: () => import("/app/pages/preview/prodotti/[id].vue")
  },
  {
    name: "preview-prodotti-id___fr",
    path: "/fr/preview/prodotti/:id()",
    component: () => import("/app/pages/preview/prodotti/[id].vue")
  },
  {
    name: "preview-risorse-id___it",
    path: "/it/preview/risorse/:id()",
    component: () => import("/app/pages/preview/risorse/[id].vue")
  },
  {
    name: "preview-risorse-id___en",
    path: "/en/preview/risorse/:id()",
    component: () => import("/app/pages/preview/risorse/[id].vue")
  },
  {
    name: "preview-risorse-id___fr",
    path: "/fr/preview/risorse/:id()",
    component: () => import("/app/pages/preview/risorse/[id].vue")
  },
  {
    name: "preview-visite-guidate-id___it",
    path: "/it/preview/visite-guidate/:id()",
    component: () => import("/app/pages/preview/visite-guidate/[id].vue")
  },
  {
    name: "preview-visite-guidate-id___en",
    path: "/en/preview/visite-guidate/:id()",
    component: () => import("/app/pages/preview/visite-guidate/[id].vue")
  },
  {
    name: "preview-visite-guidate-id___fr",
    path: "/fr/preview/visite-guidate/:id()",
    component: () => import("/app/pages/preview/visite-guidate/[id].vue")
  },
  {
    name: "registrazione-invito___it",
    path: "/it/registrazione/invito",
    meta: invitohhQEFSo506Meta || {},
    component: () => import("/app/pages/registrazione/invito.vue")
  },
  {
    name: "registrazione-invito___en",
    path: "/en/registrazione/invito",
    meta: invitohhQEFSo506Meta || {},
    component: () => import("/app/pages/registrazione/invito.vue")
  },
  {
    name: "registrazione-invito___fr",
    path: "/fr/registrazione/invito",
    meta: invitohhQEFSo506Meta || {},
    component: () => import("/app/pages/registrazione/invito.vue")
  },
  {
    name: "traduzione-mancante___it",
    path: "/it/traduzione-mancante",
    component: () => import("/app/pages/traduzione-mancante.vue")
  },
  {
    name: "traduzione-mancante___en",
    path: "/en/missing-translation",
    component: () => import("/app/pages/traduzione-mancante.vue")
  },
  {
    name: "traduzione-mancante___fr",
    path: "/fr/traduction-manquante",
    component: () => import("/app/pages/traduzione-mancante.vue")
  },
  {
    name: "visita___it",
    path: "/it/visita",
    meta: indexcyzlsj6P9EMeta || {},
    component: () => import("/app/pages/visita/index.vue")
  },
  {
    name: "visita___en",
    path: "/en/visit",
    meta: indexcyzlsj6P9EMeta || {},
    component: () => import("/app/pages/visita/index.vue")
  },
  {
    name: "visita___fr",
    path: "/fr/decouvrir",
    meta: indexcyzlsj6P9EMeta || {},
    component: () => import("/app/pages/visita/index.vue")
  },
  {
    name: "visita-pianifica-la-tua-visita-torino-piemonte-card___it",
    path: "/it/visita/pianifica-la-tua-visita/torino-piemonte-card",
    meta: torino_45piemonte_45cardwkmzCcRDRBMeta || {},
    component: () => import("/app/pages/visita/pianifica-la-tua-visita/torino-piemonte-card.vue")
  },
  {
    name: "visita-pianifica-la-tua-visita-torino-piemonte-card___en",
    path: "/en/visit/plan-your-trip/torino-piemonte-card",
    meta: torino_45piemonte_45cardwkmzCcRDRBMeta || {},
    component: () => import("/app/pages/visita/pianifica-la-tua-visita/torino-piemonte-card.vue")
  },
  {
    name: "visita-pianifica-la-tua-visita-torino-piemonte-card___fr",
    path: "/fr/decouvrir/preparer-ton-sejour/torino-piemonte-card",
    meta: torino_45piemonte_45cardwkmzCcRDRBMeta || {},
    component: () => import("/app/pages/visita/pianifica-la-tua-visita/torino-piemonte-card.vue")
  },
  {
    name: "node-id___it",
    path: "/it/node/:id",
    meta: {"middleware":["search-node-redirect"]},
    component: () => import("/app/pages/node/[id].vue")
  },
  {
    name: "node-id___en",
    path: "/en/node/:id",
    meta: {"middleware":["search-node-redirect"]},
    component: () => import("/app/pages/node/[id].vue")
  },
  {
    name: "node-id___fr",
    path: "/fr/node/:id",
    meta: {"middleware":["search-node-redirect"]},
    component: () => import("/app/pages/node/[id].vue")
  },
  {
    name: "l-pagine-id___it",
    path: "/it/l/pagine/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/pagine/[id].vue")
  },
  {
    name: "l-pagine-id___en",
    path: "/en/l/pagine/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/pagine/[id].vue")
  },
  {
    name: "l-pagine-id___fr",
    path: "/fr/l/pagine/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/pagine/[id].vue")
  },
  {
    name: "l-risorse-id___it",
    path: "/it/l/risorse/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/risorse/[id].vue")
  },
  {
    name: "l-risorse-id___en",
    path: "/en/l/risorse/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/risorse/[id].vue")
  },
  {
    name: "l-risorse-id___fr",
    path: "/fr/l/risorse/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/risorse/[id].vue")
  },
  {
    name: "l-eventi-id___it",
    path: "/it/l/eventi/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/eventi/[id].vue")
  },
  {
    name: "l-eventi-id___en",
    path: "/en/l/eventi/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/eventi/[id].vue")
  },
  {
    name: "l-eventi-id___fr",
    path: "/fr/l/eventi/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/eventi/[id].vue")
  },
  {
    name: "l-prodotti-id___it",
    path: "/it/l/prodotti/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/prodotti/[id].vue")
  },
  {
    name: "l-prodotti-id___en",
    path: "/en/l/prodotti/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/prodotti/[id].vue")
  },
  {
    name: "l-prodotti-id___fr",
    path: "/fr/l/prodotti/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/prodotti/[id].vue")
  },
  {
    name: "l-attivita-id___it",
    path: "/it/l/attivita/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/attivita/[id].vue")
  },
  {
    name: "l-attivita-id___en",
    path: "/en/l/attivita/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/attivita/[id].vue")
  },
  {
    name: "l-attivita-id___fr",
    path: "/fr/l/attivita/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/attivita/[id].vue")
  },
  {
    name: "l-pacchetti-id___it",
    path: "/it/l/pacchetti/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/pacchetti/[id].vue")
  },
  {
    name: "l-pacchetti-id___en",
    path: "/en/l/pacchetti/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/pacchetti/[id].vue")
  },
  {
    name: "l-pacchetti-id___fr",
    path: "/fr/l/pacchetti/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/pacchetti/[id].vue")
  },
  {
    name: "l-news_convention-id___it",
    path: "/it/l/news_convention/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/news_convention/[id].vue")
  },
  {
    name: "l-news_convention-id___en",
    path: "/en/l/news_convention/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/news_convention/[id].vue")
  },
  {
    name: "l-news_convention-id___fr",
    path: "/fr/l/news_convention/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/news_convention/[id].vue")
  },
  {
    name: "l-visite_guidate-id___it",
    path: "/it/l/visite_guidate/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/visite_guidate/[id].vue")
  },
  {
    name: "l-visite_guidate-id___en",
    path: "/en/l/visite_guidate/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/visite_guidate/[id].vue")
  },
  {
    name: "l-visite_guidate-id___fr",
    path: "/fr/l/visite_guidate/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/visite_guidate/[id].vue")
  },
  {
    name: "l-spettacoli-id___it",
    path: "/it/l/spettacoli/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/spettacoli/[id].vue")
  },
  {
    name: "l-spettacoli-id___en",
    path: "/en/l/spettacoli/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/spettacoli/[id].vue")
  },
  {
    name: "l-spettacoli-id___fr",
    path: "/fr/l/spettacoli/:id",
    meta: {"middleware":["search-link-redirect"]},
    component: () => import("/app/pages/l/spettacoli/[id].vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]